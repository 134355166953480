import { Component, Input, OnInit } from '@angular/core';
import { ConversionMetric, DataTableType, Variable } from 'src/app/entity/entities';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-set-metrics',
  templateUrl: './set-metrics.component.html',
  styles: ['']
})
export class SetMetricsComponent implements OnInit {

  @Input() name: DataTableType;
  @Input() label: string;
  @Input() copiedMetrics: ConversionMetric[] = [];

  maxMetrics = 10;
  variables: Variable[];
  metrics: ConversionMetric[];

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.variables = this.dataService.getVariables(this.name);
    this.metrics = this.dataService.getConversionMetrics(this.name);
  }

  addMetric() {
    if (this.metrics.length < this.maxMetrics) {
      this.metrics.push(new ConversionMetric(null, null));
    }
  }

  removeMetric(index: number) {
    this.metrics.splice(index, 1);
  }

  copyFromTimeSeries(): void {
    this.metrics = this.copiedMetrics.map(obj => ({...obj}));
  }

}
