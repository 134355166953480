import { DataTableType } from 'src/app/entity/data-table.enum';
import { SelectOption } from 'src/app/entity/multiselect/select-option';

export class Relationship {
  id: number;
  title: string;
  dataTableType: DataTableType;
  draggable: boolean;
  comparison: boolean;

  dataSelectedItems: SelectOption[] = [];
  chartTypeSelectedItems: SelectOption[] = [];
  variablesSelectedItems: SelectOption[] = [];

  dataDropdownList: SelectOption[] = [];
  chartTypeDropdownList: SelectOption[] = [];
  variablesDropdownList: SelectOption[] = [];

  constructor(
    id: number,
    title: string,
    dataTableType: DataTableType,
    dataSelectedItems: SelectOption[],
    chartTypeSelectedItems: SelectOption[],
    variablesSelectedItems: SelectOption[],
    dataDropdownList: SelectOption[] = [],
    chartTypeDropdownList: SelectOption[],
    variablesDropdownList: SelectOption[]
  ) {
    this.id = id;
    this.title = title;
    this.dataTableType = dataTableType;
    this.dataSelectedItems = dataSelectedItems;
    this.chartTypeSelectedItems = chartTypeSelectedItems;
    this.variablesSelectedItems = variablesSelectedItems;
    this.dataDropdownList = dataDropdownList;
    this.chartTypeDropdownList = chartTypeDropdownList;
    this.variablesDropdownList = variablesDropdownList;
  }

}
