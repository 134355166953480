import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CompareGraphComponent } from './pages/compare-graph/compare-graph.component';
import { ConversionMetricsComponent } from './pages/configure-project/metrics/conversion-metrics/conversion-metrics.component';
import { NewProjectComponent } from './pages/configure-project/new-project/new-project.component';
import { SetChartComponent } from './pages/configure-project/set-chart/set-chart.component';
import { UploadFileComponent } from './pages/configure-project/upload-file/upload-file.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { LessonsComponent } from './pages/lessons/lessons.component';
import { LoginComponent } from './pages/login/login.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { SelectUserTypeComponent } from './pages/select-user-type/select-user-type.component';
import { SignupComponent } from './pages/signup/signup.component';

const routes: Routes = [
  {
    path: '',
    component: SelectUserTypeComponent,
    pathMatch: 'full'
  },
  { path: 'define-conversion-metrics', component: ConversionMetricsComponent, canActivate: [AuthGuard] },
  { path: 'project-list/:lessonId', component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: 'project-configuration', component: NewProjectComponent, canActivate: [AuthGuard] },
  { path: 'compare-graph/:id', component: CompareGraphComponent, canActivate: [AuthGuard] },
  { path: 'upload-file/:name', component: UploadFileComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'class-list', component: LessonsComponent, canActivate: [AuthGuard] },
  { path: 'set-chart', component: SetChartComponent, canActivate: [AuthGuard] },
  { path: 'signup/:userType', component: SignupComponent },
  { path: 'login/:userType', component: LoginComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
