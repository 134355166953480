import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-user-type',
  templateUrl: './select-user-type.component.html',
  styleUrls: ['./select-user-type.component.css']
})
export class SelectUserTypeComponent {

  constructor(private router: Router) { }

  public login(userType: string): void {
    this.router.navigate(['login/', userType]);
  }

}
