export class ConversionMetric {
    name: string;
    formula: string;

    constructor(name: string, formula: string) {
        this.name = name;
        this.formula = formula;
    }

}
