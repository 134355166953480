import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/services';

@Component({
  selector: 'app-set-chart-panel',
  templateUrl: './set-chart-panel.component.html',
  styles: ['']
})
export class SetChartPanelComponent implements OnInit {

  isInstructor: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.isInstructor = this.userService.userIsInstructor();
  }

  public handleClick(route): void {
    if (this.isInstructor) {
      this.router.navigate([route]);
    }
  }

}
