import { VariableType } from 'src/app/entity/variable/variable-type.enum';
import { VariableValue } from 'src/app/entity/variable/variable-value';
import { Md5 } from 'ts-md5/dist/md5';

export abstract class Variable {
    md5: string;
    key: string;
    type: VariableType;
    formula: string;
    values: VariableValue[];

    constructor(index: number, key: string, type: VariableType, formula: string, values: VariableValue[]) {
        this.key = key;
        this.type = type;
        this.formula = formula;
        this.values = values;
        this.md5 = Md5.hashStr(`${index}${type}`).toString();
    }
}
