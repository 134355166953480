import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styles: ['.go-dashboard { border: none; }']
})
export class NewProjectComponent implements OnInit {

  completed = true;
  title = 'Setup';

  constructor(
    private router: Router,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.getTitle();
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  private getTitle(): void {
    const workspace = this.dataService.getWorkspace();
    this.title = workspace && workspace.name ? `${workspace.name}` : 'Setup';
  }

}
