import { Injectable } from '@angular/core';

declare function makeMagic(equation: string, variable: string): any;

@Injectable({
  providedIn: 'root'
})
export class MathService {

  constructor() { }
  
  replaceVariableValueInFormula(
    formula: string,
    variable: string,
    value: number,
    index?: number,
    unselectedDependentVariables?: any,
    varyCoefficientsIsActive?: boolean
    ): string {

      if (varyCoefficientsIsActive) {
        if (unselectedDependentVariables) {
          unselectedDependentVariables.forEach(coeff => {
            if (formula.includes(coeff.itemId.dependentVariable.formula)) {
              formula = formula.replace(coeff.itemId.dependentVariable.formula, coeff.itemId.dependentVariable.values[index].value.toString());
            }
          });
        }
      }
      return formula.includes(variable) ?
        formula.replace(new RegExp(variable, 'g'), value.toString()) : formula;
  }

  addResultToFormula(formula: string, result: number): string {
    return formula.concat('=').concat(result.toString());
  }

  solveEquation(equation: string, x: string): number {
    const solution: number = makeMagic(equation, x);
    return +solution.toFixed(2);
  }

}
