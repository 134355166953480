import { DataTableType } from 'src/app/entity/data-table.enum';
import { SelectOption } from 'src/app/entity/multiselect/select-option';
import { Serie } from 'src/app/entity/serie';
import { Variable } from 'src/app/entity/variable/variable';

export class ChartData {
    id: number;
    title: string;
    dataTableType: DataTableType;
    series: Serie[];
    chartType: SelectOption;
    variables: Variable[];
    draggable: boolean;
    comparison: boolean;
    futureDataIndex: number;

    constructor(
        id: number,
        title: string,
        dataTableType: DataTableType,
        series: Serie[],
        chartType: SelectOption,
        variables: Variable[],
        futureDataIndex: number,
        draggable: boolean,
        comparison: boolean
    ) {
        this.id = id;
        this.title = title;
        this.dataTableType = dataTableType;
        this.series = series;
        this.chartType = chartType;
        this.variables = variables;
        this.futureDataIndex = futureDataIndex;
        this.draggable = draggable;
        this.comparison = comparison;
    }

}
