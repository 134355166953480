import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/entity/entities';
import { ApiService, FormService } from 'src/app/services/services';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  hasError = false;
  userType: string;
  error = 'A problem has occurred, please check the information or try again later.';

  constructor(
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => this.userType = params.userType);

    this.signUpForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z_A-Z0-9._%+-]+@[a-z_A-Z0-9.-]+\.[a-z_A-Z]{2,4}$'),
      ]),
      repeatEmail: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z_A-Z0-9._%+-]+@[a-z_A-Z0-9.-]+\.[a-z_A-Z]{2,4}$'),
      ]),
      password: new FormControl('', [Validators.required, this.formService.trimValidator]),
      repeatPassword: new FormControl('', [Validators.required, this.formService.trimValidator]),
      firstName: new FormControl('', [Validators.required, this.formService.trimValidator]),
      lastName: new FormControl('', [Validators.required, this.formService.trimValidator]),
    }, this.emailAndPasswordMatchValidator);

  }

  emailAndPasswordMatchValidator(form: FormGroup) {
    return (form.get('email').value.toLowerCase() === form.get('repeatEmail').value.toLowerCase() &&
    form.get('password').value.toLowerCase() === form.get('repeatPassword').value.toLowerCase())
    ? null : { mismatch : true};
  }

  isRepeatEmailInvalid(): boolean {
    return !this.equalsControls(this.signUpForm, 'email', 'repeatEmail');
  }

  isRepeatPasswordInvalid(): boolean {
    return !this.equalsControls(this.signUpForm, 'password', 'repeatPassword');
  }

  private equalsControls(form: FormGroup,  nameA: string, nameB: string): boolean {
    return form.get(nameA).value.toLowerCase() === form.get(nameB).value.toLowerCase();
  }

  isInputValid(inputName: string): boolean {
    return this.formService.isInputValid(this.signUpForm, inputName);
  }

  signUp(): void {
    this.hasError = false;
    if (this.signUpForm.invalid) {
      this.hasError = true;
      this.error = 'Please check the information.';
      return;
    }

    this.checkUserAndRegister();
  }

  private checkUserAndRegister(): void {
    const user = this.getUser();
    this.apiService.checkEmail(user.email).subscribe(r => {
      if (r.data.checkEmail) {
        this.hasError = true;
        this.error = 'Email is already registered';
      } else {
        this.registerUser(user);
      }
    }, () => this.hasError = true);
  }

  private registerUser(user: User): void {
    if (this.userType === 'instructor') {
      this.instructorSignUp(user);
    } else {
      this.studentSignUp(user);
    }
  }

  private instructorSignUp(user: User): void {
    this.apiService.instructorSignUp(user).subscribe(
      response => this.processResponse(response.data.instructorSignup.saved),
      () => this.hasError = true
    );
  }

  private studentSignUp(user: User): void {
    this.apiService.studentSignUp(user).subscribe(
      response => this.processResponse(response.data.studentSignup.saved),
      () => this.hasError = true
    );
  }

  private getUser(): User {
    const email = this.signUpForm.controls.email.value.trim();
    const password = this.signUpForm.controls.password.value.trim();
    const lastName = this.signUpForm.controls.lastName.value.trim();
    const firstName = this.signUpForm.controls.firstName.value.trim();
    return new User(email, password, firstName, lastName);
  }

  private processResponse(saved: boolean): void {
    const success = saved === true;
    this.hasError = !success;

    if (success) {
      this.toastr.success('Thanks for registering');
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 1000);
    }
  }

}
