import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Lesson, Workspace } from 'src/app/entity/entities';
import { ApiService, DataService, FormService, WorkspaceService } from 'src/app/services/services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

  workspaceForm: FormGroup;
  workspace: Workspace;
  workspacesNames: string[];
  lesson: Lesson;

  subscriptionsCalls: Subscription[] = [];

  isSaved: Boolean = false;

  @ViewChild('saveModal', {static: true}) saveModal: any;
  constructor(
    private workspaceService: WorkspaceService,
    private dataService: DataService,
    private formService: FormService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.lesson = this.dataService.getLesson();
  }

  ngOnDestroy() {
    this.subscriptionsCalls.forEach(subs => subs.unsubscribe());
  }

  private loadData(): void {
    this.workspace = this.dataService.getWorkspace();
    this.createWorkspaceForm();
    this.loadWorkspacesNames();
  }

  private loadWorkspacesNames(): void {
    this.subscriptionsCalls.push(this.apiService.getLessonWorkspaces(this.lesson.id).subscribe(response => {
      try {
        this.workspacesNames = response.data.lessonWorkspaces.map(e => e.name);
      } catch (error) {}
    }));
  }

  openSaveModal() {
    this.loadData();
    this.modalService.open(this.saveModal);
  }

  private createWorkspaceForm(): void {
    const workspaceName = this.workspace.name ? this.workspace.name : '';
    this.workspaceForm = new FormGroup({
      name: new FormControl(workspaceName, [Validators.required, this.formService.trimValidator]),
      newProject: new FormControl(false, [Validators.required]),
    });
  }

  public saveOrUpdate(): void {
    if (this.workspaceForm.invalid) {
      return;
    }

    this.modalService.dismissAll();
    const name = this.workspaceForm.controls.name.value.trim();
    this.workspace.configurationData = this.dataService.getConfigurationData();
    this.workspace.name = name;

    const newProject = this.workspaceForm.controls.newProject.value;

    if (!this.workspace.id || newProject) {
      this.saveWorkspace();
    } else {
      this.updateWorkspace();
    }
  }

  private saveWorkspace(): void {
    this.workspaceService.saveWorkspace(this.workspace, this.lesson.id);
  }

  private updateWorkspace(): void {
    this.workspaceService.updateWorkspace(this.workspace);
  }

  isInputValid(inputName: string): boolean {
    return this.formService.isInputValid(this.workspaceForm, inputName);
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  goToLesson() {
    this.router.navigate(['/project-list/', this.lesson.id]);
  }

  public saveChanges(): boolean {
    try {
      return this.isSaved = true;
    } catch (error) {
      return this.isSaved = false;
    }
  }

  logout() {
    this.closeModals();
    this.dataService.clearStorage();
    this.router.navigate(['/']);
  }

}
