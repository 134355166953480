import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import 'chartjs-plugin-dragdata';

declare function setDraggableModal(): void;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: NgbModal,
    private config: NgbModalConfig,
  ) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
  }

  public openModal(modal: any) {
    this.modalService.open(modal);
    setDraggableModal();
  }

  public dismissAll() {
    this.modalService.dismissAll();
  }

}
