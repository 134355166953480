import { DataTable } from 'src/app/entity/data-table';
import { Relationship } from 'src/app/entity/relationship';

export class ConfigurationData {
  timeSeriesDataTable: DataTable;
  benchmarkDataTable: DataTable;
  relationships: Relationship[];
  keepConstant = true;
  fixedMetrics = true;
  hidden = false;
}
