import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInformation } from 'src/app/entity/entities';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  public userIsInstructor(): boolean {
    const user = this.getUser();
    return user && user.userType === 1;
  }

  public saveUser(worker: any) {
    sessionStorage.setItem('user', JSON.stringify(worker));
  }

  public getUser(): UserInformation {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  public saveToken(token: string) {
    sessionStorage.setItem('ACCESS_TOKEN', token);
  }

  public isAuthenticated() {
    const helper = new JwtHelperService();
    const token = sessionStorage.getItem('ACCESS_TOKEN');
    return !helper.isTokenExpired(token);
  }

}
