export class UserInformation {

    id: any;
    userType: any;
    firstName: string;
    lastName: string;
    isAdmin: boolean;

    constructor(userType: any) {
        this.userType = userType;
    }

}
