import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Lesson, PrimeNgSelect, Workspace } from 'src/app/entity/entities';
import { ApiService, DataService, FormService, UserService } from 'src/app/services/services';

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent implements OnInit, OnDestroy {

  userIsInstructor = false;
  lessons: Lesson[] = [];
  addLessonForm: FormGroup;
  lessonForm: FormGroup;
  lesson: Lesson;

  subscriptionsCalls: Subscription[];

  allDefaultTemplates: Workspace[];
  templates: PrimeNgSelect[];
  selectedTemplates: PrimeNgSelect[];

  @ViewChild('confirmDeleteLessonModal', {static: true}) confirmDeleteLessonModal: any;
  @ViewChild('createLessonModal', {static: true}) createLessonModal: any;
  @ViewChild('addLessonModal', {static: true}) addLessonModal: any;

  constructor(
    private formService: FormService,
    private userService: UserService,
    private dataService: DataService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private router: Router,
  ) {
    this.lessonForm = new FormGroup({
      name: new FormControl('', [Validators.required, this.formService.trimValidator]),
      code: new FormControl('', [Validators.required, this.formService.trimValidator]),
    });

    this.addLessonForm = new FormGroup({
      code: new FormControl('', [Validators.required, this.formService.trimValidator]),
    });

    this.allDefaultTemplates = [];
    this.selectedTemplates = [];
    this.subscriptionsCalls = [];
  }

  ngOnInit() {
    this.userIsInstructor = this.userService.userIsInstructor();
    this.loadLessons();
    this.loadDefaultTemplates();
  }

  ngOnDestroy() {
    this.subscriptionsCalls.forEach(subs => subs.unsubscribe());
  }

  private loadDefaultTemplates() {
    this.subscriptionsCalls.push(this.apiService.getDefaultWorkspace().subscribe(
      response => {
        this.templates = response.data.defaultWorkspace.map(template => ({code: template.id, name: template.name}));
        this.allDefaultTemplates = response.data.defaultWorkspace;
      }
    ));
  }

  goToLesson(lesson: Lesson) {
    this.dataService.saveLesson(lesson);
    this.router.navigate(['/project-list/', lesson.id]);
  }

  deleteLesson(): void {
    this.closeModals();
    if (this.lesson != null) {
      this.subscriptionsCalls.push(this.apiService.deleteLesson(this.lesson.id).subscribe(response => {
        if (response.data.deleteLesson.deleted) {
          this.toast.success('', 'Team deleted successfully');
          this.loadLessons();
        } else {
          this.toast.error('Try it again later', 'Error deleting team');
        }
      }));
    }
  }

  async createNewLesson() {
    const selectedTemplates: string[] = this.selectedTemplates.map(template => template.code);
    if (this.lessonForm.invalid) {
      return;
    }

    const name = this.lessonForm.controls.name.value.trim();
    const code = this.lessonForm.controls.code.value.trim();
    const lesson = new Lesson(name, code);

    const responseLesson = await this.apiService.createLesson(lesson).toPromise();

    const { saved } = responseLesson.data.createLesson;

    if (!saved) {
      this.toast.error(
        'Cannot create team as the code has already been used. Please change it and try again.',
        'Error creating team'
      );
      return;
    }

    if (!selectedTemplates.length) {
      this.toast.success('', 'Team created successfully');
      this.loadLessons();
      this.closeModals();
      return;
    }

    await this.asociateWorkspacesToLesson(lesson, selectedTemplates);
    this.toast.success('', 'Team created successfully');
    this.loadLessons();
    this.closeModals();
  }

  private async asociateWorkspacesToLesson(lesson: Lesson, selectedDefaultTemplates: string[]) {
    const responseLessons = await this.apiService.getLessons().toPromise();
    const newLessonCreated: Lesson = responseLessons.data.lessons.find(les => les.name === lesson.name && les.code === lesson.code);

    const workspacesPromises = [];
    selectedDefaultTemplates.forEach(async (templateId) => {
      const workspace: Workspace = this.allDefaultTemplates.find(templ => templ.id === templateId);
      workspace.configurationData.fixedMetrics = false;
      workspacesPromises.push(this.apiService.saveWorkspace(workspace, newLessonCreated.id, lesson.code).toPromise());
    });

    return new Promise((resolve) => {
      Promise.all(workspacesPromises).then(() => resolve('OK'));
    });
  }

  addLesson(): void {
    if (this.addLessonForm.invalid) {
      return;
    }

    this.closeModals();
    const code = this.addLessonForm.controls.code.value.trim();

    this.subscriptionsCalls.push(this.apiService.subscribeToLesson(code).subscribe(response => {
      if (response.data.subscribeToLesson.subscribed) {
        this.toast.success('', 'You have successfully subscribed to the team');
        this.loadLessons();
      } else {
        this.toast.error('Try it again later', 'Failed to subscribe to team');
      }
    }));
  }

  openCreateLessonModal() {
    this.modalService.open(this.createLessonModal);
  }

  openAddLessonModal() {
    this.modalService.open(this.addLessonModal);
  }

  openConfirmDeleteLessonModal(lesson: Lesson) {
    this.lesson = lesson;
    this.modalService.open(this.confirmDeleteLessonModal);
  }

  closeModals() {
    this.modalService.dismissAll();
    this.selectedTemplates = [];
    this.lessonForm.reset();
  }

  isInputValid(inputName: string): boolean {
    return this.formService.isInputValid(this.lessonForm, inputName);
  }

  private loadLessons() {
    this.lessons = [];
    this.subscriptionsCalls.push(this.apiService.getLessons().subscribe(
      response => {
        if (response.data) {
          const user = this.userService.getUser();
          this.lessons = response.data.lessons.filter(template => template.name !== 'NissanRioCase-Default');
          // TODO delete this and change for isAdmin property
          // backend doesn't return that property yet
          if ((user.firstName === "Andrés" && user.lastName === "Martiliano") || user.isAdmin) {
            this.lessons = response.data.lessons;
          }
        } else {
          this.viewLoadErrorMessage();
        }
      },
      () => this.viewLoadErrorMessage()
    ));
  }

  private viewLoadErrorMessage(): void {
    this.toast.error('Try it again later', 'Error loading teams');
  }
}
