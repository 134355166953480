import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ChartData,
  ChartDragManager,
  DataTable,
  Relationship,
  Serie,
  Variable,
  VariableValue
} from 'src/app/entity/entities';
import {
  ChartCommunicationService,
  ChartService,
  ControlsService,
  ConversionMetricsService,
  DataService,
  UserService
} from 'src/app/services/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  relationships: Relationship[];
  chartData: ChartData[];
  title = 'Dashboard';
  keepMetric = true;
  availableFutureData = false;
  hasFutureData = false;
  undoChanges = 0;
  redoChanges = 0;
  variables: Variable[] = [];

  chartDragManager: ChartDragManager;

  subscriptionsCalls: Subscription[];

  constructor(
    private chartCommunicationService: ChartCommunicationService,
    private conversionMetricsService: ConversionMetricsService,
    private controlsService: ControlsService,
    private chartService: ChartService,
    private dataService: DataService,
    private userService: UserService,
    private router: Router,
  ) {
    this.subscriptionsCalls = [];
  }

  ngOnInit() {
    this.chartDragManager = new ChartDragManager();
    this.subscribeToLoadChartsEmitter();
    this.controlsService.cleanControlsObservers();
    this.getUndoAndRedoChanges();
    this.processRelationships();
    this.getTitle();
    this.getKeepMetric();
    this.subscribeToControlsChanges();
    this.clearSelectedItems();
    this.getHasFutureData();
    this.dataService.clearSelectedItems();
    this.availableFutureData = this.chartService.getAvailableFutureData();
  }

  ngOnDestroy(): void {
    this.chartCommunicationService.cleanObservers();
    this.subscriptionsCalls.forEach(subs => subs.unsubscribe());
  }

  private subscribeToLoadChartsEmitter(): void {
    this.subscriptionsCalls.push(this.chartCommunicationService.loadChartsEmitter.subscribe(() => {
      this.processRelationships();
    }));
  }

  public loadData(): void {
    const table = this.dataService.getCurrentDataTable();
    this.variables = this.dataService.getVariables(table);
  }

  private clearSelectedItems(): void {
    this.dataService.clearSelectedItems();
  }

  private getUndoAndRedoChanges(): void {
    this.undoChanges = this.controlsService.getUndoVariablesLength();
    this.redoChanges = this.controlsService.getRedoVariablesLength();
  }

  private subscribeToControlsChanges(): void {
    this.subscriptionsCalls.push(this.controlsService.undoChangedEmitter.subscribe((data: number) => {
      this.undoChanges = data;
    }));
    this.subscriptionsCalls.push(this.controlsService.redoChangedEmitter.subscribe((data: number) => {
      this.redoChanges = data;
    }));
  }

  private getTitle(): void {
    const workspace = this.dataService.getWorkspace();
    this.title = workspace && workspace.name ? `${workspace.name}` : 'Dashboard';
  }

  private getKeepMetric(): void {
    this.keepMetric = this.chartService.keepMetric;
  }

  public hastPermissionsToEdit(): boolean {
    if (!this.userService.userIsInstructor()) {
      const workspace = this.dataService.getWorkspace();
      const fixedMetrics = workspace && workspace.configurationData && workspace.configurationData.fixedMetrics;
      return fixedMetrics;
    }
    return true;
  }

  public reset(): void {
    this.resetData();
    this.changeAvailableFutureData();
    this.dataService.clearSelectedItems();
    this.chartDragManager = new ChartDragManager();
    this.chartService.setVariableClicked(undefined);
  }

  public resetToLastSavedChanges(): void {
    this.clearControls();
    this.dataService.getDataTables().forEach(e => {
      if (e.lastChanges) {
        this.dataService.saveVariables(e.name, e.lastChanges);
        this.conversionMetricsService.calculateAndSaveDependentVariables(e.name);
      }
    });
    this.processRelationships();
  }

  private resetData() {
    this.clearControls();
    this.resetOriginalVariables();
    this.processRelationships();
  }

  private clearControls(): void {
    this.undoChanges = 0;
    this.redoChanges = 0;
    this.controlsService.clearControls();
  }

  public undo(): void {
    const variables = this.controlsService.getUndoVariables();
    this.saveNewVariables(variables);
  }

  public redo(): void {
    const variables = this.controlsService.getRedoVariables();
    this.saveNewVariables(variables);
  }

  private saveNewVariables(variables: Variable[]) {
    if (variables) {
      const dataTableName = this.dataService.getCurrentDataTable();
      this.dataService.saveVariables(dataTableName, variables);
      this.processRelationships();
    }
  }

  private resetOriginalVariables() {
    this.dataService.getDataTables().forEach(e => {
      this.dataService.saveVariables(e.name, e.originalVariables);
      this.conversionMetricsService.calculateAndSaveDependentVariables(e.name);
    });
  }

  private processRelationships(): void {
    this.relationships = this.dataService.getRelationships();
    this.chartData = this.relationships.map(data => this.createChartData(data));
  }

  private createChartData(data: Relationship): ChartData {
    const dataTable: DataTable = this.dataService.getDataTable(data.dataSelectedItems[0].itemId);
    const variables = dataTable.variables.filter(variable => {
      return data.variablesSelectedItems.find( selectedVariable => selectedVariable.itemId === variable.md5 );
    });
    const id = data.id;
    const title = data.title;
    const dataTableType = data.dataTableType;
    const series: Serie[] = dataTable.series;
    const chartType = data.chartTypeSelectedItems[0];
    const futureDataIndex = dataTable.futureDataIndex;
    const draggable = data.draggable;
    const comparison = data.comparison;
    return { id, title, dataTableType, series, chartType, variables, futureDataIndex, draggable, comparison };
  }

  public changeKeepMetric(): void {
    this.chartService.setKeepMetric(this.keepMetric);
    this.clearSelectedItems();
  }

  public changeAvailableFutureData(): void {
    this.checkAfterFuture();
    this.chartService.setAvailableFutureData(this.availableFutureData);
    this.dataService.getDataTables()
    .map(data => this.setWithLasPeriodIntoTheFuture(data))
    .forEach(data => this.dataService.saveDataTable(data));

    this.processRelationships();
  }

  private checkAfterFuture(): void {
    if (this.availableFutureData) {
      this.dataService.deleteAfterFuture();
      const tables = this.dataService.getDataTables();
      this.dataService.saveAfterFuture(tables);
      this.resetData();
    } else {
      this.resetData();
      this.dataService.getAfterFuture().map(data => this.dataService.saveDataTable(data));
      this.dataService.deleteAfterFuture();
    }
  }

  private setWithLasPeriodIntoTheFuture(data: DataTable): DataTable {
    const futureIndex = data.futureDataIndex;
    if (futureIndex >= 0) {
      data.variables.map(variable => this.setVariableWithLasPeriod(variable, futureIndex));
    }
    return data;
  }

  private setVariableWithLasPeriod(variable: Variable, futureIndex: number): Variable {
    variable.values.map((value, i) => this.setValueWithLasPeriod(variable, value, i, futureIndex) );
    return variable;
  }

  private setValueWithLasPeriod(variable: Variable, value: VariableValue, index: number, futureIndex: number) {
    if (index > futureIndex) {
      value.value = variable.values[futureIndex].value;
    }
    return value;
  }

  private getHasFutureData(): void {
    this.hasFutureData = this.dataService.getDataTables()
    .filter(e => e.futureDataIndex >= 0).length > 0;
  }

  goToConfiguration(): void {
    this.router.navigate(['/project-configuration']);
  }

}
