import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTable, IndependentVariable, Serie, VariableValue } from 'src/app/entity/entities';
import { ControlsService, DataService } from 'src/app/services/services';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent implements OnInit {

  dataTable: DataTable;
  name: string;
  fileName: string;
  title = 'Time series';

  constructor(
    private controlsService: ControlsService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.name = this.route.snapshot.paramMap.get('name');
    this.getTitle();
  }

  saveDataTable() {
    if (this.dataTable && this.name) {
      this.dataService.saveDataTable(this.dataTable);
      this.controlsService.clearControls();
      this.router.navigate(['/project-configuration']);
    }
  }

  inputExcelOnClick(evt: any): void {
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0 || target.files.length > 1) {
      console.log('Empty files');
      return;
    }

    const sheetBufferRender = target.files[0];
    this.fileName = sheetBufferRender.name;
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(sheetBufferRender);
    this.readerExcel(reader);
  }

  readerExcel(reader: FileReader, index = 0) {
    this.dataTable = null;
    reader.onload = (e: any) => {
      const excelData = this.getExceldata(e, index);
      const header = this.getTableHeader(excelData);
      const series = this.getSeries(excelData);
      const variables = this.getVariables(excelData, series);
      this.dataTable = new DataTable(this.name, header, series, variables);
    };
  }

  getVariables(excelData: any, series: Serie[]): IndependentVariable[] {
    return excelData.slice(2)
    .map((item: string[], index: number) => {
      const key = item[0];
      const data = item.slice(1, item.length);
      const values = this.getVariableValue(data, series);
      return new IndependentVariable(index, key, values );
    });
  }

  getVariableValue(items: any[], series: Serie[]): VariableValue[] {
    return items.map((item, index) => new VariableValue(series[index], item ));
  }

  getSeries(excelData: any): Serie[] {
    const headers: any[] = excelData.slice(1, 2)
    .map((item: any[]) => item.slice(1, item.length));

    return headers[0].map((key: string) => new Serie(key));
  }

  getExceldata(e: any, index = 0) {
    const data: string = e.target.result;
    const book: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
    const sheetName: string = book.SheetNames[index];
    const sheet: XLSX.WorkSheet = book.Sheets[sheetName];

    return XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false, raw: true });
  }

  getTableHeader(excelData: any[]): string {
    return excelData.slice(0, 1)
      .reduce((obj: any, val: any, i: any) => val)[0];
  }

  private getTitle(): void {
    const workspace = this.dataService.getWorkspace();
    this.title = workspace && workspace.name ? `${workspace.name}` : 'Time series';
  }
}
