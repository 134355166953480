import { IDropdownSettings } from 'ng-multiselect-dropdown';

export const MultiSelectDropdownSettings: IDropdownSettings = {
    itemsShowLimit: 1,
    idField: 'itemId',
    textField: 'name',
    singleSelection: false,
    selectAllText: 'Select all',
    unSelectAllText: 'Unselect all',
    noDataAvailablePlaceholderText: 'Select data'
};

export const SingleDropdownSettings: IDropdownSettings = {
    idField: 'itemId',
    textField: 'name',
    singleSelection: true,
    closeDropDownOnSelection: true
};
