import { EventEmitter, Injectable, Output } from '@angular/core';
import { Controls, DataTableType, Variable } from '../entity/entities';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {

  @Output()
  undoChangedEmitter = new EventEmitter<number>();

  @Output()
  redoChangedEmitter = new EventEmitter<number>();

  constructor(private dataService: DataService) { }

  public clearControls() {
    this.dataService.deleteArrayVariables(Controls.REDO, DataTableType.TimeSeries);
    this.dataService.deleteArrayVariables(Controls.UNDO, DataTableType.TimeSeries);
    this.dataService.deleteArrayVariables(Controls.REDO, DataTableType.Benchmark);
    this.dataService.deleteArrayVariables(Controls.UNDO, DataTableType.Benchmark);
  }

  public cleanControlsObservers() {
    this.undoChangedEmitter.observers = [];
    this.redoChangedEmitter.observers = [];
  }

  public saveUndoVariables(dataTableType: DataTableType): void {
    this.dataService.saveCurrentDataTable(dataTableType);
    this.setUndoVariables();
    this.saveRedoArrayVariables([]);
  }

  public getUndoVariables(): Variable[] {
    return this.makeChanges(Controls.UNDO);
  }

  public getRedoVariables(): Variable[] {
    return this.makeChanges(Controls.REDO);
  }

  public getRedoVariablesLength(): number {
    try {
      return this.dataService.getArrayVariables(Controls.REDO).length;
    } catch (error) {
      return 0;
    }
  }

  public getUndoVariablesLength(): number {
    try {
      return this.dataService.getArrayVariables(Controls.UNDO).length;
    } catch (error) {
      return 0;
    }
  }

  public hasUndoOrRedoChanges(): boolean {
    return this.getUndoVariablesLength() > 0 || this.getRedoVariablesLength() > 0;
  }

  //
  private setRedoVariables() {
    this.saveVariables(Controls.REDO, this.redoChangedEmitter);
  }

  private saveRedoArrayVariables(variables: Variable[][]) {
    this.saveArrayVariables(variables, Controls.REDO, this.redoChangedEmitter);
  }

  private setUndoVariables() {
    this.saveVariables(Controls.UNDO, this.undoChangedEmitter);
  }

  private saveUndoArrayVariables(variables: Variable[][]) {
    this.saveArrayVariables(variables, Controls.UNDO, this.undoChangedEmitter);
  }

  //

  private makeChanges(type: Controls) {
    const arrayVariables = this.getArrayVariables(type);
    const variables =  arrayVariables.pop();

    if (variables && type === Controls.UNDO) {
      this.saveUndoArrayVariables(arrayVariables);
      this.setRedoVariables();
    }

    if (variables && type === Controls.REDO) {
      this.saveRedoArrayVariables(arrayVariables);
      this.setUndoVariables();
    }

    return variables;
  }

  private saveVariables(type: Controls, emitter: EventEmitter<number>): void {
    const variables = this.dataService.getArrayVariables(type);
    variables.push(this.getCurrentVariables());

    this.saveArrayVariables(variables, type, emitter);
  }

  private saveArrayVariables(arrayVariables: Variable[][], type: Controls, emitter: EventEmitter<number>) {
    this.dataService.saveArrayVariables(type, arrayVariables);
    emitter.emit(arrayVariables.length);
  }

  private getArrayVariables(type: Controls): Variable[][] {
    return this.dataService.getArrayVariables(type);
  }

  private getCurrentVariables(): Variable[] {
    const dataTableType = this.dataService.getCurrentDataTable();
    return this.dataService.getVariables(dataTableType);
  }

}
