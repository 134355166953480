import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService, FormService, UserService } from 'src/app/services/services';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  recoveryPasswordURL: string;
  loginForm: FormGroup;
  hasError = false;
  userType: string;
  error = 'A problem has occurred, please check the credentials or try again later.';

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private formService: FormService,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
  ) {
    if (this.userService.isAuthenticated()) {
      this.navigateToProjectList();
    }

    this.activatedRoute.params.subscribe(params => this.userType = params.userType);
  }

  ngOnInit() {
    this.dataService.clearStorage();
    this.recoveryPasswordURL = environment.RECOVERY_PASSWORD_URL;
    let user = '';
    let pass = '';
    this.loginForm = new FormGroup({
      username: new FormControl(user, [Validators.required, this.formService.trimValidator]),
      password: new FormControl(pass, [Validators.required, this.formService.trimValidator])
    });
  }

  isInputValid(inputName: string): boolean {
    return this.formService.isInputValid(this.loginForm, inputName);
  }

  login(): void {
    this.hasError = false;
    if (this.loginForm.invalid) {
      return;
    }

    const username = this.loginForm.controls.username.value;
    const password = this.loginForm.controls.password.value;

    this.apiService.checkEmail(username).subscribe(r => {
      if (r.data.checkEmail) {
        this.apiService.login(username, password).subscribe(
          response => this.processResponse(response),
          () => {
            this.hasError = true;
            this.error = 'Incorrect password';
          }
        );
      } else {
        this.hasError = true;
        this.error = 'Email not exists';
      }
    }, () => this.hasError = true);

  }

  signUp(): void {
    this.router.navigate(['signup/', this.userType]);
  }

  private processResponse(response: any) {
    const token = response.data.tokenAuth.token;
    this.userService.saveToken(token);
    this.getUserAndNavigate();
  }

  private getUserAndNavigate() {
    this.apiService.getUserInformation().subscribe(response => {
      const user = response.data.userInformation;
      this.userService.saveUser(user);
    }, () => {
      console.log('Error loading worker');
    }, () => {
      this.navigateToProjectList();
    });
  }

  private navigateToProjectList(): void {
    this.router.navigate(['class-list/']);
  }

}
