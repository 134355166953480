import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChartConfig } from 'src/app/entity/chart-config';
import { ChartData, Variable, VariableClicked, VariableItem, ChartDragManager } from 'src/app/entity/entities';
import { ChartService, ControlsService, DataService, ModalService } from 'src/app/services/services';
import { MultiSelectDropdownSettings, SingleDropdownSettings } from 'src/app/tools/dropdown-settings';

import 'chart.js';
import 'chartjs-plugin-annotation';

@Component({
  selector: 'app-view-chart',
  templateUrl: './view-chart.component.html',
  styleUrls: ['./view-chart.component.css'],
})
export class ViewChartComponent implements OnInit {

  @Input() data: ChartData;
  @Input() chartDragManager: ChartDragManager;

  chartType = 'line';
  chartData: any[];
  chartOptions: any;
  chartLabels: string[];
  chartConfig: any = ChartConfig;
  maxYAxes = 100;

  hasError = false;
  hasOriginalChart = false;
  hasChanges = false;

  variableClicked: VariableClicked;

  singleDropdownSettings: IDropdownSettings = {};
  multiSelectDropdownSettings: IDropdownSettings = {};
  optionSelectedItems = [];
  optionsDropdownList = [];
  keepVariablesSelectedItems: VariableItem[] = [];
  keepVariablesDropdownList: VariableItem[] = [];
  @ViewChild('dependentVariableModal', {static: true}) dependentVariableModal: any;
  @ViewChild('keepConstantVariableModal', {static: true}) keepConstantVariableModal: any;
  @ViewChild('keepLastConfigurationModal', {static: true}) keepLastConfigurationModal: any;

  constructor(
    private controlsService: ControlsService,
    private chartService: ChartService,
    private dataService: DataService,
    private modal: ModalService,
  ) {
  }

  ngOnInit(): void {
    this.loadChart();

    this.singleDropdownSettings = SingleDropdownSettings;
    this.multiSelectDropdownSettings = MultiSelectDropdownSettings;
    this.hasOriginalChart = this.dataService.getOriginalWorkspace().id != null;
  }

  private loadChart(): void {
    try {
      this.hasChanges = this.controlsService.hasUndoOrRedoChanges();
      this.maxYAxes = this.chartService.getMaxYAxes(this.data.variables);
      this.setChartType();
      this.setChartOptions();
      this.setChartLabels();
      this.setChartData();
    } catch (error) {
      this.hasError = true;
    }
  }

  private setChartType(): void {
    this.chartType = this.data.chartType.itemId;
  }

  private setChartOptions(): void {
    this.chartOptions = this.chartService.getChartOptions(this.data, this.maxYAxes, this, this.data.draggable);
  }

  private setChartLabels(): void {
    this.chartLabels = this.data.series.map(e => e.key);
  }

  private setChartData(): void {
    this.chartData = this.chartService.chartDataConfigurations(this.data.variables, this.data.futureDataIndex - 1);
  }

  private onDragStart(e: any, element: any): void {
    this.controlsService.saveUndoVariables(this.data.dataTableType);
  }

  private onDragEnd(event: any, datasetIndex: any, index: any, value: any): void {
    this.processChanges(datasetIndex, index, value);
  }

  public dismissAllModals(): void {
    this.modal.dismissAll();
    this.loadChart();
  }

  public getKeepMetric(): boolean {
    return this.chartService.keepMetric;
  }

  public onSelectDependentVariableOption(event: any): void {
    this.chartService.setChangeVariableKey(event.itemId.key);
  }

  private processChanges(variableIndex: number, seriesIndex: number, newValue: number): void {
    const variable = this.data.variables[variableIndex];
    this.variableClicked = new VariableClicked(this.data.dataTableType, variable, seriesIndex, newValue);
    this.chartService.setVariableClicked(this.variableClicked);

    this.chartService.processIndependentVariable();
    if (!this.chartDragManager.isChartPreviouslyDragged(
      this.data,
      variableIndex
    )) {
      this.chartDragManager.setTempData(
        this.data,
        variableIndex,
        seriesIndex,
        newValue
      );
      this.processIndependentVariableAndKeepMetric();
      this.processDependentVariable();

    } else {
      this.chartDragManager.setTempData(
        this.data,
        variableIndex,
        seriesIndex,
        newValue
      );
      const chart = this.chartDragManager.findChart(
        this.data,
        variableIndex
      );
      if (chart.shouldKeepLastConfig) {
        this.checkLastConfiguration(true);
      } else {
        this.modal.openModal(this.keepLastConfigurationModal);
      }
    }
  }

  private processIndependentVariableAndKeepMetric(): void {
    if (this.chartService.variableClickedIsIndependent && this.chartService.keepMetric) {
      this.checkSelectedDataAndProcess();
    }
  }

  private checkSelectedDataAndProcess(): void {
    if (this.chartService.hasSelectedData) {
      this.modal.openModal(this.keepLastConfigurationModal);
    }

    if (!this.chartService.hasSelectedData && this.chartService.variableClickedIsIndependent) {
      this.processKeepConversionMetric();
    }

    if (!this.chartService.hasSelectedData && this.chartService.variableClickedIsDependent) {
      this.optionSelectedItems = [];
      this.optionsDropdownList = this.chartService.getDropdownOptions();

      if (this.chartService.keepMetric) {
        const dependentVariables =  this.chartService.getDependentsVariablesExcludeVariableClicked();
        this.setKeepVariablesDropdownListAndOpenModal(dependentVariables, this.dependentVariableModal);
      } else {
        this.modal.openModal(this.dependentVariableModal);
      }
    }
  }

  public checkLastConfiguration(lastConfiguration: boolean): void {
    this.modal.dismissAll();
    if (lastConfiguration) {
      const data = this.chartDragManager.getTempData();
      this.chartDragManager.setChartDragged(
        data.tempData,
        data.tempVariableIndex,
        data.tempSeriesIndex,
        data.tempNewValue,
      );
      const lConfiguration = this.chartDragManager.getVariablesSelected(
        data.tempData,
        data.tempVariableIndex
      );
      this.chartDragManager.storeChartDragged(
        lConfiguration.keepVariablesSelectedItems,
        lConfiguration.keepVariablesDropdownList,
        lConfiguration.optionSelectedItems,
        lConfiguration.optionsDropdownList
      );
    }

    if (!lastConfiguration) {
      this.dataService.deleteSelectItems(this.chartService.variable().md5);
    }

    if (lastConfiguration && this.chartService.variableClickedIsIndependent) {
      this.processIndependentVariableAndKeepMetricWithSavedData();
    }

    if (!lastConfiguration && this.chartService.variableClickedIsIndependent) {
      this.checkSelectedDataAndProcess();
    }

    if (lastConfiguration && this.chartService.variableClickedIsDependent) {
      this.processDependentVariableWithSavedData();
    }

    if (!lastConfiguration && this.chartService.variableClickedIsDependent) {
      this.checkSelectedDataAndProcess();
    }
  }

  private processIndependentVariableAndKeepMetricWithSavedData(): void  {
    this.setKeepVariablesSelectedItems();
    this.keepConversionsManager();
  }

  private processDependentVariable(): void {
    if (this.chartService.variableClickedIsDependent) {
      this.checkSelectedDataAndProcess();
    }
  }

  private processDependentVariableWithSavedData(): void {
    this.setDependentSelectedItems();
    this.processDependentVariableManager();
  }

  private processKeepConversionMetric(): void {
    const dependentVariables = this.chartService.getSelectedDependentVariables();
    this.setKeepVariablesDropdownListAndOpenModal(dependentVariables, this.keepConstantVariableModal);
  }

  private setKeepVariablesDropdownListAndOpenModal(variables: Variable[], modal: any): void {
    this.keepVariablesDropdownList = this.chartService.getKeepVariablesDropdownList(variables);
    this.modal.openModal(modal);
  }


  public keepConversionsManager(shouldUpdateVariables?: boolean): void {
    const data = this.chartDragManager.getTempData();
    this.chartDragManager.setChartDragged(
      data.tempData,
      data.tempVariableIndex,
      data.tempSeriesIndex,
      data.tempNewValue,
    );

    const lConfiguration = this.chartDragManager.getVariablesSelected(
      data.tempData,
      data.tempVariableIndex
    );
    if (lConfiguration && !shouldUpdateVariables) {
      this.chartDragManager.storeChartDragged(
        lConfiguration.keepVariablesSelectedItems ,
        lConfiguration.keepVariablesDropdownList,
        lConfiguration.optionSelectedItems,
        lConfiguration.optionsDropdownList
      );
      this.chartService.saveSelectedData(
        lConfiguration.keepVariablesSelectedItems,
        lConfiguration.keepVariablesDropdownList,
      );
    } else if (shouldUpdateVariables) {
      this.chartDragManager.storeChartDragged(
        this.keepVariablesSelectedItems ,
        this.keepVariablesDropdownList,
        this.optionSelectedItems,
        this.optionsDropdownList
      );
      this.chartService.saveSelectedData(
        this.keepVariablesSelectedItems,
        this.keepVariablesDropdownList,
      );
    }

    if (this.chartService.variableClickedIsDependent) {
      if (lConfiguration) {
        this.chartService.updateAndEmitChanges(
          lConfiguration.keepVariablesSelectedItems,
          lConfiguration.optionSelectedItems,
          lConfiguration.keepVariablesDropdownList,
        );
      } else {
        this.chartService.updateAndEmitChanges(
          this.keepVariablesSelectedItems,
          this.optionSelectedItems,
          this.keepVariablesDropdownList,
        );
      }
      const dependentVariables = this.chartService.getSelectedDependentVariables();
      this.chartDragManager.updateDependentVariables(
        this.chartService.getKeepVariablesDropdownList(dependentVariables),
        );
    } else {
      this.independentVariableWithKeepConversion();
    }

    this.modal.dismissAll();
  }

  private independentVariableWithKeepConversion(): void {
    const data = this.chartDragManager.getData();
    const variablesSelected = this.chartDragManager.getVariablesSelected(
      data.data,
      data.variableIndex
    );
    const results = [];
    results.push({ key: this.variableClicked.variable.key , value: this.variableClicked.newValue });
    const unselectedItems = [];
    variablesSelected.keepVariablesDropdownList.forEach(item => {
      const selected = variablesSelected.keepVariablesSelectedItems.find(i => i.name === item.name);
      if (!selected) {
        unselectedItems.push(item);
      }
    });
    this.chartService.updateIndependentVariables(results, unselectedItems.map(element => element.itemId));
    this.data.variables = this.chartService.filterByChartVariables(this.data.variables);
    const independentVariables = data.data.variables.map(variable => variable.key);
    this.chartService.calculateIndependentVariables(unselectedItems, independentVariables, this.variableClicked.seriesIndex);
    this.chartService.calculateDependentVariables(unselectedItems);
  }

  public processDependentVariableManager(shouldUpdateVariables?: boolean): void {
    if (this.chartService.keepMetric) {
      this.keepConversionsManager(shouldUpdateVariables);
    } else {
      const data = this.chartDragManager.getTempData();
      this.chartDragManager.setChartDragged(
        data.tempData,
        data.tempVariableIndex,
        data.tempSeriesIndex,
        data.tempNewValue,
      );
      this.chartDragManager.storeChartDragged(
        this.keepVariablesSelectedItems,
        this.keepVariablesDropdownList,
        this.optionSelectedItems,
        this.optionsDropdownList,
      );
      const lConfiguration = this.chartDragManager.getVariablesSelected(
        data.tempData,
        data.tempVariableIndex
      );

      if (lConfiguration && !shouldUpdateVariables) {
        this.chartDragManager.storeChartDragged(
          lConfiguration.keepVariablesSelectedItems ,
          lConfiguration.keepVariablesDropdownList,
          lConfiguration.optionSelectedItems,
          lConfiguration.optionsDropdownList
        );
        this.chartService.saveSelectedData(
          lConfiguration.keepVariablesSelectedItems,
          lConfiguration.keepVariablesDropdownList,
        );
      } else if (shouldUpdateVariables) {
        this.chartDragManager.storeChartDragged(
          this.keepVariablesSelectedItems ,
          this.keepVariablesDropdownList,
          this.optionSelectedItems,
          this.optionsDropdownList
        );
      }
      this.chartService.processDependentVariable();
      this.modal.dismissAll();
    }
  }

  private setKeepVariablesSelectedItems(): void {
    this.keepVariablesSelectedItems = this.chartService.getKeepVariablesSelectedItems();
  }

  private setDependentSelectedItems(): void {
    const itemMD5 = this.chartService.variable().md5;
    const data = this.dataService.getSelectedItems(itemMD5);
    this.chartService.setChangeVariableKey(data.changeVariableKey);

    if (this.chartService.keepMetric) {
      this.setKeepVariablesSelectedItems();
    }
  }

}
