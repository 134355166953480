import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataTable, Workspace } from 'src/app/entity/entities';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  private errorText = 'An error occurred while trying to save the project. (try another name)';
  private duplicatedErrorText = 'Cannot create team as the code has already been used. Please change it and try again.';

  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private utils: UtilsService,
  ) { }

  public saveWorkspace(workspace: Workspace, lessonId: any, code?: string) {
    workspace = this.setLastChanges(workspace);

    this.apiService.saveWorkspace(workspace, lessonId, code).subscribe(response => {
      if (response.data && response.data.addWorkspace.saved) {
        this.toastr.success('', 'Project saved correctly!');
        this.dataService.saveWorkspace(response.data.addWorkspace.workspace);
      } else {
        this.viewErrorAlert(this.errorText);
      }
    }, () => this.viewErrorAlert(this.errorText));
  }

  public updateWorkspace(workspace: Workspace) {
    workspace = this.setLastChanges(workspace);

    this.apiService.updateWorkspace(workspace).subscribe(response => {
      if (response.data && response.data.updateWorkspace.updated) {
        this.toastr.success('', 'Project updated correctly!');
      } else {
        this.viewErrorAlert(this.duplicatedErrorText);
      }
    }, () => this.viewErrorAlert(this.duplicatedErrorText));
  }

  private viewErrorAlert(text): void {
    this.toastr.error('', text);
  }

  private setLastChanges(workspace: Workspace): Workspace {
    try {
      if (workspace && workspace.configurationData) {
        workspace.configurationData.timeSeriesDataTable = this.replaceLastChanges(workspace.configurationData.timeSeriesDataTable);
        workspace.configurationData.benchmarkDataTable = this.replaceLastChanges(workspace.configurationData.benchmarkDataTable);
      }
    } catch (error) {}
    return workspace;
  }

  private replaceLastChanges(dataTable: DataTable): DataTable {
    if (dataTable) {
      dataTable.lastChanges = dataTable.variables.filter(e => this.utils.isIndependent(e));
    }
    return dataTable;
  }

}
