import { Injectable } from '@angular/core';
import { ConversionMetric, DataTableType, DependentVariable, Serie, Variable, VariableValue } from 'src/app/entity/entities';
import { DataService } from 'src/app/services/data.service';
import { MathService } from 'src/app/services/math.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ConversionMetricsService {

  constructor(
    private mathService: MathService,
    private dataService: DataService,
    private utils: UtilsService
  ) { }

  public calculateAndSaveDependentVariables(dataTableType: DataTableType, unselectedDependentVariables?: any, varyCoefficientsIsActive?: boolean): void {
    const dependentVariables = this.calculateDependentVariables(dataTableType, unselectedDependentVariables, varyCoefficientsIsActive);
    const dataTable = this.dataService.getDataTable(dataTableType);
    if (dataTable) {
      dataTable.variables = dataTable.variables
      .filter(variable =>  this.utils.isIndependent(variable))
      .concat(dependentVariables);
      this.dataService.saveDataTable(dataTable);
    }
  }

  private calculateDependentVariables(dataTableType: DataTableType, unselectedDependentVariables?: any, varyCoefficientsIsActive?: boolean): DependentVariable[] {
    try {
      const series = this.dataService.getSeries(dataTableType);
      const metrics = this.dataService.getConversionMetrics(dataTableType);
      const variables = this.dataService.getVariables(dataTableType);
      return metrics
      .map((metric, i: number) => this.calculateDependentVariable(series, variables, metric, i, unselectedDependentVariables, varyCoefficientsIsActive));
    } catch (error) {
      console.error(error.message);
      return [];
    }
  }

  private calculateDependentVariable(
    series: Serie[],
    variables: Variable[],
    metric: ConversionMetric,
    index: number,
    unselectedDependentVariables?: any,
    varyCoefficientsIsActive?: boolean
  ) {
    const values = series
    .map(() => metric.formula)
    .map((formula, i) => this.replaceFormula(variables, formula, i, unselectedDependentVariables, varyCoefficientsIsActive))
    .map(data => this.evalData(data))
    .map((value, i) => this.assignSerieToValue(series, value, i));
    return new DependentVariable(index, metric.name, metric.formula, values);
  }

  private assignSerieToValue(series: Serie[], value: string, index: number): VariableValue {
    return new VariableValue(series[index], value);
  }

  private evalData(data: string): string {
    try {
      // tslint:disable-next-line: no-eval
      return eval(data).toFixed(2);
    } catch (error) {
      console.error(error.message);
      return '#ERROR!';
    }
  }

  private replaceFormula(variables: Variable[], formula: string, index: number, unselectedDependentVariables?: any, varyCoefficientsIsActive?: boolean): string {
    variables.forEach(variable => formula = this.replace(formula, variable, index, unselectedDependentVariables, varyCoefficientsIsActive));
    return formula;
  }

  private replace(formula: string, variable: Variable, index: number, unselectedDependentVariables?: any, varyCoefficientsIsActive?: boolean): string {
    return this.mathService.replaceVariableValueInFormula(
      formula,
      variable.key,
      variable.values[index].value,
      index,
      unselectedDependentVariables,
      varyCoefficientsIsActive
    );
  }

}
