import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService, UserService } from 'src/app/services/services';

@Component({
  selector: 'app-define-conversion-metrics-panel',
  templateUrl: './conversion-metrics-panel.component.html',
  styles: ['']
})
export class ConversionMetricsPanelComponent implements OnInit {

  disabled = true;
  isInstructor: boolean;

  constructor(
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.disabled = !this.dataService.getWorkspace().configurationData.fixedMetrics;
    this.isInstructor = this.userService.userIsInstructor();
  }

  public handleClick(route: string) {
    if (!this.isDisabled()) {
      this.router.navigate([route]);
    }
  }

  public isDisabled(): boolean {
    if (this.isInstructor) {
      return false;
    }
    return this.disabled;
  }

}
