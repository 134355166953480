import { isDevMode } from '@angular/core';
import {
  VariableItem,
  ChartData,
  Variable,
  ChartDragged,
  VariablesSelected,
  TempData,
  DraggedData
} from "./entities";

export class ChartDragManager {
  private chartsDragged: ChartDragged[];

  private data: ChartData;
  private variableIndex: number;
  private seriesIndex: number;
  private newValue: number;

  private tempData: ChartData;
  private tempVariableIndex: number;
  private tempSeriesIndex: number;
  private tempNewValue: number;

  constructor() {
    this.chartsDragged = [];
    this.resetChardDragged();
    this.resetTempChardDragged();
  }

  public setTempData(
    tempData: ChartData,
    tempVariableIndex: number,
    tempSeriesIndex: number,
    tempNewValue: number
  ): void {
    this.tempData = tempData;
    this.tempVariableIndex = tempVariableIndex;
    this.tempSeriesIndex = tempSeriesIndex;
    this.tempNewValue = tempNewValue;
  }

  public getTempData(): TempData {
    return {
      tempData: this.tempData,
      tempVariableIndex: this.tempVariableIndex,
      tempSeriesIndex: this.tempSeriesIndex,
      tempNewValue: this.tempNewValue,
    }
  }

  public getData(): DraggedData {
    return {
      data: this.data,
      variableIndex: this.variableIndex,
      seriesIndex: this.seriesIndex,
      newValue: this.newValue,
    }
  }

  public shouldKeepLastConfig(
    data: ChartData,
    variableIndex: number,
  ): boolean {
    if (this.isChartPreviouslyDragged(
      data,
      variableIndex
    )) {
      const chartDragged = this.findChart(data, variableIndex);
      return chartDragged.shouldKeepLastConfig;
    }
    return false;
  }

  public findChart(
    data: ChartData,
    variableIndex: number,
  ): ChartDragged {
    const variable: Variable = data.variables[variableIndex];
    return this.chartsDragged.find(
      chart => chart.id === data.id && (chart.variableDragedName === variable.key)
    );
  }

  public isChartPreviouslyDragged(
    data: ChartData,
    variableIndex: number,
  ): boolean {
    if (!this.chartsDragged.length) {
      return false;
    }
    const chartDragged = this.findChart(data, variableIndex);
    return (!chartDragged) ? false : true;
  }

  public setChartDragged(
    data: ChartData,
    variableIndex: number,
    seriesIndex: number,
    newValue: number
  ): void {
    this.data = data;
    this.variableIndex = variableIndex;
    this.seriesIndex = seriesIndex;
    this.newValue = newValue
  }

  public getVariablesSelected(
    data: ChartData,
    variableIndex: number,
  ): VariablesSelected {
    const chart = this.findChart(data, variableIndex);
    if (!chart && isDevMode()) {
      console.log('no chart found');
      return;
    }
    if (isDevMode()) {
      console.log(`chart ${chart.id} + ${chart.variableDragedName} + index: ${variableIndex}`);
    }
    return {
      keepVariablesSelectedItems: chart.keepVariablesSelectedItems,
      keepVariablesDropdownList: chart.keepVariablesDropdownList,
      optionSelectedItems: chart.optionSelectedItems,
      optionsDropdownList: chart.optionsDropdownList,
    }
  }

  private resetChardDragged(): void {
    this.data = null;
    this.variableIndex = null;
    this.seriesIndex = null;
    this.newValue = null;
  }

  public resetTempChardDragged(): void {
    this.tempData = null;
    this.tempVariableIndex = null;
    this.tempSeriesIndex = null;
    this.tempNewValue = null;
  }

  public storeChartDragged(
    keepVariablesSelectedItems: VariableItem[],
    keepVariablesDropdownList: VariableItem[],
    optionSelectedItems: any[],
    optionsDropdownList: any[],
  ): void {
    if (!this.data) return;
    this.restoreChartsDragged();

    let chartDragged = this.findChart(this.data, this.variableIndex);

    if (!chartDragged) {
      const variable: Variable = this.data.variables[this.variableIndex];
      chartDragged = {
        shouldKeepLastConfig: true,
        keepVariablesSelectedItems,
        keepVariablesDropdownList,
        optionSelectedItems,
        optionsDropdownList,
        id: this.data.id,
        variableDragedName: variable.key
      }
      this.chartsDragged.push(chartDragged);
    } else {
      this.chartsDragged = this.chartsDragged.map(
        chart => {
          if (chart.id === chartDragged.id && chart.variableDragedName === chartDragged.variableDragedName) {
            return {
              ...chart,
              shouldKeepLastConfig: true,
              keepVariablesSelectedItems,
              keepVariablesDropdownList,
              optionSelectedItems,
              optionsDropdownList,
            }
          }
          return chart;
        }
      );
    }
  }

  public updateDependentVariables(
    dependentVariables: VariableItem[],
  ): void {
    this.chartsDragged = this.chartsDragged.map(
      chart => {
        let { keepVariablesDropdownList, keepVariablesSelectedItems } = chart;

        keepVariablesDropdownList = this.updateDependantVariable(
          dependentVariables,
          keepVariablesDropdownList,
        );
        keepVariablesSelectedItems = this.updateDependantVariable(
          dependentVariables,
          keepVariablesSelectedItems,
        );

        const newChart = {
          ...chart,
          keepVariablesDropdownList,
          keepVariablesSelectedItems,
        };

        return newChart;
      });

  }

  private updateDependantVariable(
    dependentVariables: VariableItem[],
    variablesToUpdate: VariableItem[],
  ): VariableItem[] {
    const newDependetVariables: VariableItem[] = [];

    variablesToUpdate.forEach(dependentVariable => {
      const hasDependantVariable = dependentVariables.find(variable => variable.name === dependentVariable.name);
      if (hasDependantVariable) {
        newDependetVariables.push(hasDependantVariable);
      } else {
        newDependetVariables.push(dependentVariable);
      }
    });

    return newDependetVariables;
  }

  public continueLastConfig(): void {
    if (!this.tempData) return;
    this.restoreChartsDragged();
    const chartDragged = this.findChart(this.tempData, this.tempVariableIndex);
    this.chartsDragged = this.chartsDragged.map(
      chart => {
        if (chart.id === chartDragged.id && chart.variableDragedName === chartDragged.variableDragedName) {
          const newChart = {
            ...chart,
            shouldKeepLastConfig: true,
          };
          return newChart;
        }
        return chart;
      }
    );
  }

  private restoreChartsDragged(): void {
    this.chartsDragged = this.chartsDragged.map(
      chart => ({ ...chart, shouldKeepLastConfig: false})
    );
  }

}
