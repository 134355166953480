import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableType } from 'src/app/entity/entities';
import { DataService, UserService } from 'src/app/services/services';

@Component({
  selector: 'app-upload-data-panel',
  templateUrl: './upload-data-panel.component.html',
  styles: ['']
})
export class UploadDataPanelComponent implements OnInit {

  timeSeries: any;
  benchmark: any;

  isInstructor: boolean;

  constructor(
    private dataService: DataService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.timeSeries = this.dataService.getDataTable(DataTableType.TimeSeries);
    this.benchmark = this.dataService.getDataTable(DataTableType.Benchmark);
    this.isInstructor = this.userService.userIsInstructor();
  }

  public handleClick(type: string): void {
    if (this.isInstructor) {
      this.router.navigate(['upload-file', type]);
    }
  }

}
