import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { GraphQLModule } from './graphql.module';
import { ChartComponent } from './pages/chart/chart.component';
import { CompareGraphComponent } from './pages/compare-graph/compare-graph.component';
// tslint:disable-next-line: max-line-length
import { ConversionMetricsPanelComponent } from './pages/configure-project/metrics/conversion-metrics-panel/conversion-metrics-panel.component';
import { ConversionMetricsComponent } from './pages/configure-project/metrics/conversion-metrics/conversion-metrics.component';
import { SetMetricsComponent } from './pages/configure-project/metrics/set-metrics/set-metrics.component';
import { NewProjectComponent } from './pages/configure-project/new-project/new-project.component';
import { SetChartPanelComponent } from './pages/configure-project/set-chart-panel/set-chart-panel.component';
import { SetChartComponent } from './pages/configure-project/set-chart/set-chart.component';
import { UploadDataPanelComponent } from './pages/configure-project/upload-data-panel/upload-data-panel.component';
import { UploadFileComponent } from './pages/configure-project/upload-file/upload-file.component';
import { ViewChartComponent } from './pages/dashboard/chart/view-chart/view-chart.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { TableComponent } from './pages/data-table/table/table.component';
import { LessonsComponent } from './pages/lessons/lessons.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { LogoutExtraComponent } from './pages/logout-extra/logout-extra.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { SelectUserTypeComponent } from './pages/select-user-type/select-user-type.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SponsorshipImageComponent } from './pages/sponsorship-image/sponsorship-image.component';
import { ApiService } from './services/api.service';
import { ChartCommunicationService } from './services/chart/chart-communication.service';
import { ChartService } from './services/chart/chart.service';
import { ControlsService } from './services/controls.service';
import { ConversionMetricsService } from './services/conversion-metrics.service';
import { DataService } from './services/data.service';
import { FormService } from './services/form.service';
import { MathService } from './services/math.service';
import { ModalService } from './services/modal.service';
import { UserService } from './services/user.service';
import { UtilsService } from './services/utils.service';
import { WorkspaceService } from './services/workspace.service';


import { ListboxModule } from 'primeng/listbox';

@NgModule({
  declarations: [
    AppComponent,
    ConversionMetricsPanelComponent,
    ConversionMetricsComponent,
    SponsorshipImageComponent,
    UploadDataPanelComponent,
    SelectUserTypeComponent,
    SetChartPanelComponent,
    CompareGraphComponent,
    ProjectListComponent,
    NewProjectComponent,
    SetMetricsComponent,
    UploadFileComponent,
    DashboardComponent,
    ViewChartComponent,
    SetChartComponent,
    LessonsComponent,
    LogoutComponent,
    LogoutExtraComponent,
    SignupComponent,
    ChartComponent,
    LoginComponent,
    TableComponent,
    MenuComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({ positionClass: 'toast-bottom-left' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserModule,
    GraphQLModule,
    ChartsModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ListboxModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    ChartCommunicationService,
    ConversionMetricsService,
    WorkspaceService,
    ControlsService,
    ChartService,
    ModalService,
    UtilsService,
    DataService,
    FormService,
    MathService,
    UserService,
    ApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
