import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataTable, DataTableType, Relationship, SelectOption, Variable } from 'src/app/entity/entities';
import { ControlsService, DataService } from 'src/app/services/services';
import { MultiSelectDropdownSettings, SingleDropdownSettings } from 'src/app/tools/dropdown-settings';
import { getDateTableType } from 'src/app/tools/tools';

@Component({
  selector: 'app-set-chart',
  templateUrl: './set-chart.component.html',
  styleUrls: ['./set-chart.component.css']
})
export class SetChartComponent implements OnInit {

  maxRelationships = 6;
  relationships: Relationship[] = [];

  dropdownSettings: IDropdownSettings = {};
  singleDropdownSettings: IDropdownSettings = {};

  title = 'Setup';

  constructor(
    private controlsService: ControlsService,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dropdownSettings = MultiSelectDropdownSettings;
    this.singleDropdownSettings = SingleDropdownSettings;
    this.loadAndValidateRelationships();
    this.getTitle();
  }

  private loadAndValidateRelationships() {
    this.relationships = this.dataService.getRelationships()
    .map(relationship => {
      this.setRelationshipVariableDropdownList(relationship);
      this.setRelationshipVariablesSelectItems(relationship);
      return relationship;
    });

    this.dataService.saveRelationships(this.relationships);
  }

  private setRelationshipVariablesSelectItems(relationship: Relationship): void {
    const variables = this.dataService.getDataTable(relationship.dataTableType).variables;

    relationship.variablesSelectedItems = this.updateNameSelectedItems(relationship.variablesSelectedItems, variables)
    .filter(item => this.findItemInVariables(variables, item));
  }

  private updateNameSelectedItems(selectedItems: SelectOption[], variables: Variable[]): SelectOption[] {
    return selectedItems.map(item => this.updateItemName(item, variables));
  }

  private updateItemName(item: SelectOption, variables: Variable[]): SelectOption {
    const variable = this.findItemInVariables(variables, item);
    if (variable) {
      item.name = variable.key;
    }
    return item;
  }

  private findItemInVariables(variables: Variable[], item: SelectOption): Variable {
    return variables.find(variable => variable.md5 === item.itemId );
  }

  onSelectData(item: SelectOption, relationshipIndex: number): void {
    this.setItemsVariables(item.itemId, relationshipIndex);
  }

  onDeSelectData(relationshipIndex: number): void {
    this.setItemsVariables(null, relationshipIndex);
  }

  addRelationship(): void {
    if (this.relationships.length < this.maxRelationships) {
      this.relationships.push({
        id: this.relationships.length + 1,
        title: null,
        dataTableType: null,
        draggable: true,
        comparison: true,
        dataSelectedItems: [],
        chartTypeSelectedItems: [{ itemId: 'line', name: 'Line' }],
        variablesSelectedItems: [],
        dataDropdownList: this.getDataItems(),
        chartTypeDropdownList: this.getChartTypeItems(),
        variablesDropdownList: []
      });
    }
  }

  removeRelationship(index: number): void {
    this.relationships.splice(index, 1);
  }

  private getDataItems(): SelectOption[] {
    return this.dataService.getDataTables()
    .map((dataTable: DataTable) => this.createSelectOption(dataTable));
  }

  private createSelectOption(dataTable: DataTable) {
    const optionName = dataTable.name === DataTableType.TimeSeries ? 'Time Series' : 'Benchmark';
    return new SelectOption(dataTable.name, optionName);
  }

  private getChartTypeItems(): SelectOption[] {
    return [
      { itemId: 'line', name: 'Line' },
      { itemId: 'bar', name: 'Column' }
    ];
  }

  private setItemsVariables(itemId: string, relationshipIndex: number): void {
    const relationship = this.relationships[relationshipIndex];
    relationship.dataTableType = getDateTableType(itemId);
    relationship.variablesSelectedItems = [];
    relationship.variablesDropdownList = [];
    this.setRelationshipVariableDropdownList(relationship);
  }

  private setRelationshipVariableDropdownList(relationship: Relationship): void {
    const dataTableType = relationship.dataTableType;
    if (dataTableType) {
      relationship.variablesDropdownList = this.getVariableDropdownList(dataTableType);
    }
  }

  private getVariableDropdownList(dataTableType: DataTableType): SelectOption[] {
    return this.dataService.getDataTable(dataTableType).variables
      .map((variable: Variable) => new SelectOption(variable.md5, variable.key));
  }

  public saveRelationships(): void {
    this.controlsService.clearControls(),
    this.dataService.saveRelationships(this.relationships);
    this.router.navigate(['/project-configuration']);
  }

  private getTitle(): void {
    const workspace = this.dataService.getWorkspace();
    this.title = workspace && workspace.name ? `${workspace.name}` : 'Setup';
  }

}
