import { Serie } from 'src/app/entity/serie';

export class VariableValue {
    serie: Serie;
    value: any;

    constructor(serie: Serie, value: string) {
        this.serie = serie;
        this.value = value;
    }
}
