import { Injectable } from '@angular/core';
import { Variable } from '../entity/variable/variable';
import { VariableType } from '../entity/variable/variable-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public variableEquals(a: Variable, b: Variable): boolean {
    return a.md5 === b.md5;
  }

  public isDependent(variable: Variable): boolean {
      return variable.type === VariableType.Dependent;
  }

  public isIndependent(variable: Variable): boolean {
      return variable.type === VariableType.Independent;
  }

}
