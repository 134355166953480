import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConversionMetric } from 'src/app/entity/entities';
import { ControlsService, ConversionMetricsService, DataService, UserService } from 'src/app/services/services';
import { SetMetricsComponent } from '../set-metrics/set-metrics.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-define-conversion-metrics',
  templateUrl: './conversion-metrics.component.html',
  styles: ['']
})
export class ConversionMetricsComponent implements OnInit {

  title = 'Conversion metrics';

  @ViewChild('timeSeries', { static: false })
  private timeSeries: SetMetricsComponent;

  @ViewChild('benchmark', { static: false })
  private benchmarkComponent: SetMetricsComponent;

  @ViewChild('goToConfigurationModal', {static: true}) goToConfigurationModal: any;


  constructor(
    private conversionMetricsService: ConversionMetricsService,
    private controlsService: ControlsService,
    private dataService: DataService,
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getTitle();
  }

  save() {
    this.controlsService.clearControls();
    this.processMetrics(this.timeSeries);
    this.processMetrics(this.benchmarkComponent);
    this.goToBack();
  }
  
  openGoToConfigurationModal(): void {
    this.modalService.open(this.goToConfigurationModal);
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  goToBack(): void {
    const route = this.userService.userIsInstructor() ? '/project-configuration' : '/dashboard';
    this.router.navigate([route]);
  }

  goToConfiguration() {
    this.closeModals();
    this.goToBack()
  }

  private processMetrics(component: SetMetricsComponent) {
    if (component) {
      const metrics = this.getMetrics(component);
      const dataTableType = component.name;
      this.dataService.saveConversionMetrics(dataTableType, metrics);
      this.conversionMetricsService.calculateAndSaveDependentVariables(dataTableType);
    }
  }

  private getMetrics(component: SetMetricsComponent): ConversionMetric[] {
    return component.metrics.filter(e => e.formula && e.formula.trim().length > 0 && e.name && e.name.trim().length > 0);
  }

  private getTitle(): void {
    const workspace = this.dataService.getWorkspace();
    this.title = workspace && workspace.name ? `${workspace.name}` : 'Conversion metrics';
  }

}
