import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/services';

@Component({
  selector: 'app-logout-extra',
  templateUrl: './logout-extra.component.html',
  styles: ['']
})
export class LogoutExtraComponent implements OnInit {

  @ViewChild('logOutModal', {static: true}) logOutModal: any;

  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  openLogOutModal(): void {
    this.modalService.open(this.logOutModal);
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  logout() {
    this.closeModals();
    this.dataService.clearStorage();
    this.router.navigate(['/']);
  }

}
