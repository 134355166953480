import { Injectable } from '@angular/core';
import {
  ConfigurationData,
  Controls,
  ConversionMetric,
  DataTable,
  DataTableType,
  Lesson,
  Relationship,
  SelectedItems,
  Serie,
  Variable,
  Workspace
} from 'src/app/entity/entities';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  public saveDataTable(dataTable: DataTable): void {
    this.saveData(`${dataTable.name}DataTable`, dataTable);
  }

  public getDataTable(dataTableName: string): DataTable {
    return dataTableName ? this.getData(`${dataTableName}DataTable`) : null;
  }

  public getDataTables(): DataTable[] {
    const data = [];
    const timeSeries = this.getDataTable(DataTableType.TimeSeries);
    const benchmark = this.getDataTable(DataTableType.Benchmark);
    if (timeSeries) { data[0] = timeSeries; }
    if (benchmark) { data[1] = benchmark; }
    return data;
  }

  public saveConversionMetrics(dataTableName: string, metrics: ConversionMetric[]): void {
    const dataTable = this.getDataTable(dataTableName);
    if (dataTable) {
      dataTable.metrics = metrics;
      this.saveDataTable(dataTable);
    }
  }

  public getConversionMetrics(dataTableName: string): ConversionMetric[] {
    const dataTable = this.getDataTable(dataTableName);
    return dataTable && dataTable.metrics ? dataTable.metrics : [];
  }

  public saveRelationships(relationships: Relationship[]) {
    this.saveData('relationships', relationships);
  }

  public getRelationships(): Relationship[] {
    const relationships = this.getData('relationships');
    return relationships ? relationships : [];
  }

  public saveVariables(dataTableName: string, variables: Variable[]): void {
    const dataTable = this.getDataTable(dataTableName);
    dataTable.variables = variables;
    this.saveDataTable(dataTable);
  }

  public getVariables(dataTableName: string): Variable[] {
    const dataTable = this.getDataTable(dataTableName);
    return dataTable && dataTable.variables ? dataTable.variables : [];
  }

  public getOriginalVariables(dataTableName: string): Variable[] {
    const dataTable = this.getDataTable(dataTableName);
    return dataTable && dataTable.originalVariables ? dataTable.originalVariables : [];
  }

  public getSeries(dataTableName: string): Serie[] {
    const dataTable = this.getDataTable(dataTableName);
    return dataTable && dataTable.series ? dataTable.series : [];
  }

  public saveWorkspace(workspace: Workspace): void {
    sessionStorage.setItem('workspace', JSON.stringify(workspace));
  }

  public saveOriginalWorkspace(workspace: Workspace): void {
    sessionStorage.setItem('originalWorkspace', JSON.stringify(workspace));
  }

  public getOriginalWorkspace(): Workspace {
    return this.workspace('originalWorkspace');
  }

  public getWorkspace(): Workspace {
    return this.workspace('workspace');
  }

  public clearStorage(): void {
    sessionStorage.clear();
  }

  public saveConfigurationData(configurationData: ConfigurationData): void {
    const workspace = this.getWorkspace();
    workspace.configurationData = configurationData;
    this.saveWorkspace(workspace);
  }

  public getConfigurationData(): ConfigurationData {
    return this.getWorkspace().configurationData ? this.getWorkspace().configurationData :  new ConfigurationData();
  }

  public saveCurrentDataTable(dataTableName: string): void {
    sessionStorage.setItem('currentDataTable', dataTableName);
  }

  public getCurrentDataTable(): string {
    return sessionStorage.getItem('currentDataTable');
  }

  public getTimeSeriesVariables(): Variable[] {
    return this.getVariables(DataTableType.TimeSeries);
  }

  public saveArrayVariables(type: string, arrayVariables: Variable[][]): void {
    const dataTableName = this.getCurrentDataTable();
    if (dataTableName) {
      sessionStorage.setItem(`${dataTableName}${type}`, JSON.stringify(arrayVariables));
    }
  }

  public deleteArrayVariables(type: Controls, dataTableName: DataTableType) {
    sessionStorage.removeItem(`${dataTableName}${type}`);
  }

  public getArrayVariables(type: string): Variable[][] {
    const name = `${this.getCurrentDataTable()}${type}`;
    const arrayVariables = JSON.parse(sessionStorage.getItem(name));
    return arrayVariables ? arrayVariables : [];
  }

  public saveLesson(lesson: Lesson): void {
    sessionStorage.setItem('lesson', JSON.stringify(lesson));
  }

  public getLesson(): Lesson {
    const lesson = sessionStorage.getItem('lesson');
    return JSON.parse(lesson);
  }


  private saveData(name: string, data: any): void {
    const configurationData = this.getConfigurationData();
    configurationData[name] = data;
    this.saveConfigurationData(configurationData);
  }

  private getData(name: string) {
    const configurationData = this.getConfigurationData();
    return configurationData ? configurationData[name] : null;
  }

  private workspace(name: string): Workspace {
    const workspace = JSON.parse(sessionStorage.getItem(name));
    return workspace ? workspace : {};
  }

  public clearSelectedItems(): void {
    sessionStorage.removeItem('selectedItems');
  }

  public lastMovedVariable(variableMD5: string): void {
    sessionStorage.setItem('lastMovedVariable', variableMD5);
  }

  public getLastMovedVariable(): string {
    return sessionStorage.getItem('lastMovedVariable');
  }

  public getSelectedItems(variableMD5: string): SelectedItems {
    return this.selectedItems.find(e => e.variable === variableMD5);
  }

  private get selectedItems(): SelectedItems[] {
    const selectedItems = sessionStorage.getItem('selectedItems');
    return selectedItems ? JSON.parse(selectedItems) : [];
  }

  public saveSelectedItems(items: SelectedItems): void {
    const selectedItems = [...this.selectedItems];
    const index = this.selectedItems.findIndex(e => e.variable === items.variable);
    if (index > -1) {
      selectedItems[index] = items;
    } else {
      selectedItems.push(items);
    }

    sessionStorage.setItem('selectedItems', JSON.stringify(selectedItems));
  }

  public deleteSelectItems(variableMD5: string): void {
    const index = this.selectedItems.findIndex(e => e.variable === variableMD5);
    if (index > -1) {
      const items = [...this.selectedItems];
      items.splice(index, 1);
      sessionStorage.setItem('selectedItems', JSON.stringify(items));
    }
  }

  public deleteAfterFuture(): void {
    sessionStorage.removeItem('afterFuture');
  }

  public saveAfterFuture(data: DataTable[]): void {
    sessionStorage.setItem('afterFuture', JSON.stringify(data));
  }

  public getAfterFuture(): DataTable[] {
    const data = sessionStorage.getItem('afterFuture');
    return data ? JSON.parse(data) : [];
  }

}
