import { Variable } from 'src/app/entity/variable/variable';

export const ChartConfig = (
  { title, maxYAxes, draggable, context, annotationValue}:
  { title: string, maxYAxes: number, draggable: boolean, context: any, annotationValue: string}
) => {
  const chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 12,
      fontColor: '#212121',
      text: title,
      padding: 4,
    },
    legend: {
      labels: {
        usePointStyle: true,
        fontColor: '#212121',
        fontSize: 10
      }
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: false
        },
        position: 'bottom',
        ticks: {
          fontColor: '#212121',
          autoSkip: false,
          fontSize: 10,
          callback: (tick: any) => tick.toLocaleString()
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: false
        },
        id: 'A',
        position: 'left',
        type: 'linear',
        ticks: {
          min: 0,
          fontColor: '#212121',
          fontSize: 10,
          max: maxYAxes,
        },
      }]
    },
    dragData: draggable,
    dragX: false,
    dragDataRound: 10,
    annotation: {
      annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: annotationValue,
        borderColor: 'black',
        borderWidth: 2,
      },
    ]
    },
    onDragStart: (e: any, element: any) => context.onDragStart(e, element),
    onDragEnd: (event: any, datasetIndex: any, index: any, value: any) => context.onDragEnd(event, datasetIndex, index, value)
  };

  return chartOptions;
};


export const ChartDataConfiguration = ({data, variable, color}: {data: any[], variable: Variable, color: string}) => {
  return {
    lineTension: 0,
    data,
    label: variable.key.substring(0, 13),
    yAxisID: 'A',
    pointHitRadius: 5,
    pointRadius: 5,
    pointHoverRadius: 5,
    borderColor: color,
    pointBackgroundColor: color,
    pointHoverBackgroundColor: color,
    pointBorderColor: '#ffffff',
    pointHoverBorderColor: '#bdbdbd',
    fill: false,
  };
};
