export class ChartApp {
    chartType = 'line';
    chartData: any[];
    chartOptions: any;
    chartLabels: string[];

    constructor(
        chartType: string,
        chartData: any[],
        chartOptions: any,
        chartLabels: string[]
    ) {
        this.chartType = chartType;
        this.chartData = chartData;
        this.chartOptions = chartOptions;
        this.chartLabels = chartLabels;
    }

}
