import { ConversionMetric } from 'src/app/entity/conversion-metric';
import { DataTableType } from 'src/app/entity/data-table.enum';
import { Serie } from 'src/app/entity/serie';
import { Variable } from 'src/app/entity/variable/variable';
import { getDateTableType } from 'src/app/tools/tools';

export class DataTable {
    name: DataTableType;
    header: string;
    series: Serie[];
    variables: Variable [];
    originalVariables: Variable [];
    lastChanges: Variable[];
    metrics: ConversionMetric[];
    futureDataIndex: number;

    constructor(
        name: string,
        header: string,
        series: Serie[],
        variables: Variable[]
    ) {
        this.name = getDateTableType(name);
        this.header = header;
        this.series = series;
        this.variables = variables;
        this.originalVariables = variables;
    }

}
