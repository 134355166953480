import { ConfigurationData } from 'src/app/entity/configuration-data';

export class Workspace {

    id: any;
    creationDate: Date;
    name: string;
    configurationData: ConfigurationData;

    constructor(
        name: string,
        configurationData: ConfigurationData
    ) {
        this.name = name;
        this.configurationData = configurationData;
    }

}
