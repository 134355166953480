export const Colors: string[] = [
    'rgb(31,73,125)',
    'rgb(192,80,77)',
    'rgb(155,187,89)',
    'rgb(128,100,162)',
    'rgb(75,192,178 )',
    'rgb(247,150,70)',
    'rgb(64,64,64)',
    'rgb(128,128,128)',
    'rgb(79,129,189)',
    'rgb(255,255,0)',
    'rgb(148,138,84)',
    'rgb(29,27,16)',
    'rgb(31,73,125)',
    'rgb(192,80,77)',
    'rgb(155,187,89)',
    'rgb(128,100,162)',
    'rgb(75,192,178 )',
    'rgb(247,150,70)',
    'rgb(64,64,64)',
    'rgb(128,128,128)',
    'rgb(79,129,189)',
    'rgb(255,255,0)',
    'rgb(148,138,84)',
    'rgb(29,27,16)'
  ];
