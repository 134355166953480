export class SelectOption {

    itemId: string;
    name: string;

    constructor(itemId: string, name: string) {
        this.itemId = itemId;
        this.name = name;
    }

}
