import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { Lesson, User, Workspace } from 'src/app/entity/entities';

const GET_LESSON_WORKSPACES = gql`
  query lessonWorkspaces($lessonId: UUID!) {
    lessonWorkspaces(lessonId: $lessonId) {
      id
      configurationData
      creationDate
      name
    }
  }
`;

const GET_DEFAULT_LESSON_WORKSPACES = gql`
  query defaultLessonWorkspace($lessonId: UUID!) {
    defaultLessonWorkspace(lessonId: $lessonId) {
      id
      configurationData
      creationDate
      name
    }
  }
`;

const GET_WORKSPACE = gql`
  query workspace($id: UUID!) {
    workspace(id: $id) {
      configurationData
      creationDate
      id
      name
      worker {
        id
      }
    }
  }
`;

const GET_USER_INFORMATION = gql`
  query userInformation {
    userInformation {
      id
      firstName
      lastName
      userType
    }
  }
`;


const ADD_WORKSPACE = gql`
  mutation addWorkspace($configurationData: JSONString!, $lessonId: UUID!, $name: String!) {
    addWorkspace(configurationData: $configurationData, lessonId: $lessonId, name: $name) {
      saved
      workspace {
        id
        creationDate
        name
        configurationData
      }
    }
  }
`;

const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($id: UUID!, $name: String!, $configurationData: JSONString!) {
    updateWorkspace(id: $id, name: $name, configurationData: $configurationData) {
      updated
    }
  }
`;

const LOGIN = gql`
mutation ($username: String!, $password: String!) {
  tokenAuth(username:$username, password:$password) {
    token
  }
}
`;

const STUDENT_SIGN_UP = gql`
  mutation studentSignup($email: String!, $username: String!, $password: String!, $firstName: String!, $lastName: String!) {
    studentSignup(email: $email, username:$username, password:$password, firstName:$firstName, lastName:$lastName) {
      saved
    }
  }
`;

const INSTRUCTOR_SIGN_UP = gql`
  mutation instructorSignup($email: String!, $username: String!, $password: String!, $firstName: String!, $lastName: String!) {
    instructorSignup(email: $email, username:$username, password:$password, firstName:$firstName, lastName:$lastName) {
      saved
    }
  }
`;

const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($id: UUID!) {
    deleteWorkspace(id: $id) {
      deleted
    }
  }
`;

const GET_LESSONS = gql`
  query lessons {
    lessons {
      id
      name
      creationDate
      code
    }
  }
`;

const CREATE_LESSON = gql`
  mutation createLesson($name: String!, $code: String!) {
    createLesson(name: $name, code: $code) {
      saved
    }
  }
`;

const SUBSCRIBE_TO_LESSON = gql`
  mutation subscribeToLesson($lessonCode: String!) {
    subscribeToLesson(lessonCode: $lessonCode) {
      subscribed
    }
  }
`;

const DELETE_LESSON = gql`
  mutation deleteLesson($lessonId: UUID!) {
    deleteLesson(lessonId: $lessonId) {
      deleted
    }
  }
`;

const CHECK_EMAIL = gql`
  query checkEmail($email: String!) {
    checkEmail(email: $email)
  }
`;

const DEFAULT_WORKSPACE = gql`
  query defaultWorkspace {
    defaultWorkspace{
      id,
      name,
      configurationData
    }
  }
`;

const DEFAULT_WORKSPACE_NOT_SELECTED = gql`
  query workspace_not_selected($lessonId: UUID!) {
    workspaceNotSelected(lessonId: $lessonId){
      id,
      name,
      configurationData
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private apollo: Apollo) { }

  public getLessonWorkspaces(lessonId: any): Observable<any> {
    return this.apollo.query({
      query: GET_LESSON_WORKSPACES,
      fetchPolicy: 'no-cache',
      variables: {
        lessonId
      },
    });
  }

  public getDefaultWorkspace(): Observable<any> {
    return this.apollo.query({
      query: DEFAULT_WORKSPACE ,
      fetchPolicy: 'no-cache',
    });
  }

  public getDefaultWorkspaceNotSelected(lessonId: string): Observable<any> {
    return this.apollo.query({
      query: DEFAULT_WORKSPACE_NOT_SELECTED,
      fetchPolicy: 'no-cache',
      variables: {
        lessonId
      },
    });
  }

  public getDefaultLessonWorkspaces(lessonId: any): Observable<any> {
    return this.apollo.query({
      query: GET_DEFAULT_LESSON_WORKSPACES,
      fetchPolicy: 'no-cache',
      variables: {
        lessonId
      },
    });
  }

  public getWorkspace(id: any): Observable<any> {
    return this.apollo.query({
      query: GET_WORKSPACE,
      fetchPolicy: 'no-cache',
      variables: {
        id
      }
    });
  }

  public getUserInformation(): Observable<any> {
    return this.apollo.query({
      query: GET_USER_INFORMATION,
      fetchPolicy: 'no-cache',
    });
  }

  public saveWorkspace(workspace: Workspace, lessonId: any, code?: string): Observable<any> {
    const newName = (code) ? `${code + workspace.name}`.trim() : workspace.name;
    return this.apollo.mutate({
      mutation: ADD_WORKSPACE,
      variables: {
        configurationData: JSON.stringify(workspace.configurationData),
        lessonId,
        name: newName
      }
    });
  }

  public updateWorkspace(workspace: Workspace): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_WORKSPACE,
      variables: {
        configurationData: JSON.stringify(workspace.configurationData),
        id: workspace.id,
        name: workspace.name
      }
    });
  }

  public deleteWorkspace(id: any): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_WORKSPACE,
      variables: {
        id
      }
    });
  }

  public login(username: string, password: string): Observable<any> {
    return this.apollo.mutate({
      mutation: LOGIN,
      variables: {
        username: username.toLowerCase(),
        password
      }
    });
  }

  public instructorSignUp(user: User): Observable<any> {
    return this.apollo.mutate({
      mutation: INSTRUCTOR_SIGN_UP,
      variables: {
        email: user.email.toLowerCase(),
        username: user.email.toLowerCase(),
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
      }
    });
  }

  public studentSignUp(user: User): Observable<any> {
    return this.apollo.mutate({
      mutation: STUDENT_SIGN_UP,
      variables: {
        email: user.email.toLowerCase(),
        username: user.email.toLowerCase(),
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
      }
    });
  }

  public getLessons(): Observable<any> {
    return this.apollo.query({
      query: GET_LESSONS ,
      fetchPolicy: 'no-cache',
    });
  }

  public createLesson(lesson: Lesson): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_LESSON,
      variables: {
        name: lesson.name,
        code: lesson.code,
      }
    });
  }

  public subscribeToLesson(lessonCode: string): Observable<any> {
    return this.apollo.mutate({
      mutation: SUBSCRIBE_TO_LESSON,
      variables: {
        lessonCode,
      }
    });
  }

  public deleteLesson(lessonId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_LESSON,
      variables: {
        lessonId,
      }
    });
  }

  public checkEmail(email: string): Observable<any> {
    return this.apollo.query({
      query: CHECK_EMAIL ,
      fetchPolicy: 'no-cache',
      variables: {
        email: email.toLowerCase()
      }
    });
  }

}
