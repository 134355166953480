import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfig } from 'src/app/entity/chart-config';
import { ChartApp, ChartData, Relationship } from 'src/app/entity/entities';
import { ChartService, DataService } from 'src/app/services/services';

import 'chart.js';
import 'chartjs-plugin-dragdata';

@Component({
  selector: 'app-compare-graph',
  templateUrl: './compare-graph.component.html',
  styles: ['']
})
export class CompareGraphComponent implements OnInit {

  id: number;
  originalChart: ChartApp;
  currentChart: ChartApp;

  constructor(
    private activatedRoute: ActivatedRoute,
    private chartService: ChartService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = +params.id;
      this.loadOriginalGraph();
      this.loadCurrentChart();
    });
  }

  private loadOriginalGraph(): void {
    try {
      const workSpace = this.dataService.getOriginalWorkspace();
      const configurationData = workSpace.configurationData;
      const relationship = this.findRelationship(configurationData.relationships);
      const dataTableName = `${relationship.dataTableType}DataTable`;
      const dataTable = configurationData[dataTableName];

      const originalChart = this.chartService.getChartData(relationship, dataTable);
      this.originalChart = this.loadChart(originalChart);
    } catch (error) {
      console.error(error.message);
    }
  }

  private loadCurrentChart() {
    try {
      const relationship = this.findRelationship(this.dataService.getRelationships());
      const dataTableType = relationship.dataTableType;
      const dataTable = this.dataService.getDataTable(dataTableType);
      const currentChart = this.chartService.getChartData(relationship, dataTable);
      this.currentChart = this.loadChart(currentChart);
    } catch (error) {
      console.error(error.message);
    }
  }

  private loadChart(data: ChartData): ChartApp {
    const chartType = data.chartType.itemId;
    const chartData = this.chartService.chartDataConfigurations(data.variables, data.futureDataIndex - 1, false);
    const maxYAxes = this.chartService.getMaxYAxes(data.variables);
    const chartOptions = ChartConfig({
      title: '',
      maxYAxes,
      draggable: false,
      context: this,
      annotationValue: ''
    });
    const chartLabels = this.chartService.getChartLabels(data);

    return new ChartApp(chartType, chartData, chartOptions, chartLabels);
  }

  private findRelationship(relationships: Relationship[]) {
    return relationships.find(e => e.id === this.id);
  }

}
