import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  public isInputValid(form: FormGroup, inputName: string): boolean {
    const control = form.get(inputName);
    return control.invalid && control.touched;
  }

  public trimValidator(control: FormControl) {
    const formValue = (control.value);
    if (!formValue) return null;
    const isValid = (control.value).trim().length >= 1;
    return isValid ? null : { whitespace: true };
  }

}
