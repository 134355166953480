import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsorship-image',
  templateUrl: './sponsorship-image.component.html',
  styleUrls: ['./sponsorship-image.component.css']
})
export class SponsorshipImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
