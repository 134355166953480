import { DataTableType } from 'src/app/entity/data-table.enum';
import { Variable } from 'src/app/entity/variable/variable';

export class VariableClicked {

    dataTableType: DataTableType;
    variable: Variable;
    seriesIndex: number;
    newValue: number;

    constructor(
        dataTableType: DataTableType,
        variable: Variable,
        seriesIndex: number,
        newValue: number
    ) {
      this.dataTableType = dataTableType;
      this.variable = variable;
      this.seriesIndex = seriesIndex;
      this.newValue = newValue;
    }
}
