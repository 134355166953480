import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.userService.isAuthenticated();
    if (!isAuthenticated) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

}
