import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTable, DataTableType, Variable, VariableType } from 'src/app/entity/entities';
import { ConversionMetricsService, DataService, UserService } from 'src/app/services/services';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input() data: DataTable;

  indexRowEditing = -1;
  isRowEditing = false;
  futureDataRowIndex: number;

  isInstructor: boolean;

  @ViewChild('editModal', {static: true}) editModal: any;
  constructor(
    private conversionMetricsService: ConversionMetricsService,
    private dataService: DataService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.isInstructor = this.userService.userIsInstructor();
  }

  isNumber(value: any) {
    return typeof value === 'number';
  }

  editing(index: number) {
    this.indexRowEditing = index;
    this.isRowEditing = true;
  }

  isEditing(index: number): boolean {
    return index === this.indexRowEditing && this.isRowEditing;
  }

  save() {
    const variable = this.data.variables[this.indexRowEditing];
    this.updateVariable(variable, this.indexRowEditing);
    this.dataService.saveDataTable(this.data);

    this.conversionMetricsService.calculateAndSaveDependentVariables(this.data.name);
    this.data = this.dataService.getDataTable(this.data.name);
    this.isRowEditing = false;
  }

  setFutureData(index: number): void {
    if (this.data.name === DataTableType.TimeSeries) {
      this.data.futureDataIndex = this.data.futureDataIndex === index ? -1 : index;
      this.dataService.saveDataTable(this.data);
    }
  }

  copyNamesFromTimeSeries() {
    if (this.equalTimeSeriesLength && this.isInstructor) {
      const timeSeriesVariables = this.dataService.getTimeSeriesVariables();
      this.data.variables.map((variable, index) => this.copyNames(variable, timeSeriesVariables, index));
      this.updateVariables();
      this.dataService.saveDataTable(this.data);
    }
  }

  equalTimeSeriesLength(): boolean {
    const timeSeriesVariables = this.dataService.getTimeSeriesVariables();
    return timeSeriesVariables && timeSeriesVariables.length === this.data.variables.length;
  }

  deleteColumn(serieIndex): void {
    this.data.series.splice(serieIndex, 1);
    this.data.variables.forEach(e => e.values.splice(serieIndex, 1));
    this.data.originalVariables.forEach(e => e.values.splice(serieIndex, 1));
    this.dataService.saveDataTable(this.data);
  }

  deleteRow(index: number): void {
    this.data.variables.splice(index, 1);
    this.data.originalVariables.splice(index, 1);
    this.dataService.saveDataTable(this.data);
  }

  private copyNames(variable: Variable, timeSeriesVariables: Variable[], index: number) {
    variable.key = timeSeriesVariables[index].key;
    return variable;
  }

  private updateVariables() {
    this.data.variables.forEach((variable, index) => {
      this.updateVariable(variable, index);
    });
  }

  private updateVariable(variable: Variable, index: number) {
    if (variable.type === VariableType.Independent) {
      this.data.originalVariables[index] = variable;
    } else {
      this.updateMetrics(variable);
    }
  }

  private updateMetrics(variable: Variable): void {
    this.data.metrics = this.data.metrics.map(metric => {
      metric.name = metric.formula === variable.formula ? variable.key : metric.name;
      return metric;
    });
  }

}
