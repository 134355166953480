import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Lesson, PrimeNgSelect, Workspace } from 'src/app/entity/entities';
import { ApiService, ControlsService, DataService, UserService, WorkspaceService } from 'src/app/services/services';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styles: ['.logout{ max-width: 60rem; }']
})
export class ProjectListComponent implements OnInit, OnDestroy {

  workspaces: Workspace[] = [];
  isUserInstructor = false;
  workspace: Workspace;
  lessonId: any;
  lesson: Lesson;
  updateProjectForm: FormGroup;

  subscriptionsCalls: Subscription[];

  allDefaultTemplates: Workspace[];
  templates: PrimeNgSelect[];
  selectedTemplates: PrimeNgSelect[];

  @ViewChild('confirmDeleteWorkspaceModal', {static: true}) confirmDeleteWorkspaceModal: any;
  @ViewChild('updateProjectModal', {static: true}) updateProjectModal: any;
  @ViewChild('addTemplatesModal', {static: true}) addTemplatesModal: any;

  constructor(
    private workspaceService: WorkspaceService,
    private controlsService: ControlsService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private userService: UserService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private router: Router,
  ) {
    this.allDefaultTemplates = [];
    this.selectedTemplates = [];
    this.subscriptionsCalls = [];
  }

  ngOnInit() {
    this.subscriptionsCalls.push(this.activatedRoute.params.subscribe(params => {
      this.lessonId = params.lessonId;
      this.loadLessons();
    }));

    this.isUserInstructor = this.userService.userIsInstructor();
    this.loadWorkspaces();
    this.loadDefaultTemplates();
  }

  ngOnDestroy() {
    this.subscriptionsCalls.forEach(subs => subs.unsubscribe());
  }

  private loadLessons() {
    this.subscriptionsCalls.push(this.apiService.getLessons().subscribe(
      response => {
        if (response.data) {
          this.lesson = response.data.lessons.find(les => les.id === this.lessonId);
        }
      }
    ));
  }

  private loadDefaultTemplates() {
    this.subscriptionsCalls.push(this.apiService.getDefaultWorkspaceNotSelected(this.lessonId).subscribe(
      response => {
        this.templates = response.data.workspaceNotSelected.map(template => ({code: template.id, name: template.name}));
        this.allDefaultTemplates = response.data.workspaceNotSelected;
      }
    ));
  }

  public goToDashboard(workspace: Workspace) {
    this.clearControls(workspace);

    this.subscriptionsCalls.push(this.apiService.getWorkspace(workspace.id).subscribe(response => {
      if (response.data && response.data.workspace) {
        const workSpace = response.data.workspace;
        this.dataService.saveWorkspace(workSpace);
        this.dataService.saveOriginalWorkspace(workSpace);
        this.router.navigate(['/dashboard']);
      }
    }, () => this.viewLoadErrorMessage()));
  }

  private clearControls(selectedWorkspace: Workspace): void {
    const workspace =  this.dataService.getWorkspace();
    if (selectedWorkspace.id !== workspace.id) {
      this.controlsService.clearControls();
    }
  }

  public openConfirmDeleteWorkspaceModal(workspace: Workspace) {
    this.workspace = workspace;
    this.modalService.open(this.confirmDeleteWorkspaceModal);
  }

  public deleteWorkspace() {
    this.closeModals();
    if (this.workspace != null) {
      this.subscriptionsCalls.push(this.apiService.deleteWorkspace(this.workspace.id).subscribe(() => {
        this.templates = [];
        this.allDefaultTemplates = [];
        this.toast.success('', 'Project deleted successfully');
        this.loadWorkspaces();
        this.loadDefaultTemplates();
      }));
    }
  }

  public fromTemplate() {
    if (!this.templates.length) {
      this.toast.info('', 'there is not more Projects to add.');
      return;
    }
    this.modalService.open(this.addTemplatesModal);
  }

  public createNewProject() {
    this.dataService.saveWorkspace(null);
    this.dataService.saveOriginalWorkspace(null);
    this.router.navigate(['/project-configuration']);
  }

  public closeModals() {
    this.selectedTemplates = [];
    this.modalService.dismissAll();
  }

  public async updateTemplates() {
    const selectedTemplates: string[] = this.selectedTemplates.map(template => template.code);

    if (!selectedTemplates.length) {
      this.closeModals();
      return;
    }

    await this.asociateWorkspacesToLesson(this.lesson, selectedTemplates);
    this.toast.success('', 'Project created successfully');
    this.loadWorkspaces();
    this.closeModals();
    this.loadDefaultTemplates();
  }

  private async asociateWorkspacesToLesson(lesson: Lesson, selectedDefaultTemplates: string[]) {
    const workspacesPromises = [];
    selectedDefaultTemplates.forEach(async (templateId) => {
      const workspace: Workspace = this.allDefaultTemplates.find(templ => templ.id === templateId);
      workspace.configurationData.fixedMetrics = false;
      workspacesPromises.push(this.apiService.saveWorkspace(workspace, lesson.id, lesson.code).toPromise());
    });

    return new Promise((resolve) => {
      Promise.all(workspacesPromises).then(() => resolve('OK'));
    });
  }

  private loadWorkspaces() {
    this.subscriptionsCalls.push(this.apiService.getLessonWorkspaces(this.lessonId).subscribe(
      response => {
        this.workspaces = this.processResponse(response, 'lessonWorkspaces');
      },
      () => this.viewLoadErrorMessage()
    ));
  }

  private processResponse(response: any, property: string): Workspace [] {
    if (response.data && response.data[property]) {
      return response.data[property];
    } else {
      this.viewLoadErrorMessage();
      return [];
    }
  }

  private viewLoadErrorMessage(): void {
    this.toast.error('Try it again later', 'Error loading Projects');
  }

  openUpdateProjectModal(workspace: Workspace): void {
    this.workspace = workspace;
    this.updateProjectForm = new FormGroup({
      fixedMetrics: new FormControl(workspace.configurationData.fixedMetrics, [Validators.required]),
      hidden: new FormControl(workspace.configurationData.hidden, [Validators.required]),
    });
    this.modalService.open(this.updateProjectModal);
  }

  updateProject(): void {
    this.workspace.configurationData.fixedMetrics = this.updateProjectForm.controls.fixedMetrics.value;
    this.workspace.configurationData.hidden = this.updateProjectForm.controls.hidden.value;
    this.workspaceService.updateWorkspace(this.workspace);
    setTimeout(() => {
      this.closeModals();
      this.loadWorkspaces();
    }, 1000);
  }

}
