import { Injectable } from '@angular/core';
import {
  ChartData,
  DataTable,
  DataTableType,
  ItemId,
  NewIndependentValue,
  Relationship,
  SelectedItems,
  SelectOption,
  Variable,
  VariableClicked,
  VariableItem,
  VariableValue
} from 'src/app/entity/entities';
import { ChartConfig, ChartDataConfiguration } from 'src/app/entity/chart-config';
import { ChartCommunicationService } from 'src/app/services/chart/chart-communication.service';
import { ConversionMetricsService } from 'src/app/services/conversion-metrics.service';
import { DataService } from 'src/app/services/data.service';
import { MathService } from 'src/app/services/math.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Colors } from 'src/app/tools/colors';

interface KeyValue {
  key: string,
  value: number
}

interface PairIndependetDependetVariables {
  independent: Variable,
  dependent: VariableItem
}

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private variableClicked: VariableClicked;
  private availableFutureData = false;
  private changeVariableKey: string;

  private textMatch = /[A-Za-z]+/g;

  constructor(
    private chartCommunicationService: ChartCommunicationService,
    private conversionMetricsService: ConversionMetricsService,
    private dataService: DataService,
    private mathService: MathService,
    private utils: UtilsService
  ) {}

  public getAvailableFutureData(): boolean {
    return this.availableFutureData;
  }

  public setAvailableFutureData(value: boolean): void {
    this.availableFutureData = value;
  }

  public setChangeVariableKey(changeVariableKey: string): void {
    this.changeVariableKey = changeVariableKey;
  }

  public setKeepMetric(keepMetric: boolean): void {
    const configuration = this.dataService.getConfigurationData();
    configuration.keepConstant = keepMetric;
    this.dataService.saveConfigurationData(configuration);
  }

  public get keepMetric(): boolean {
    return this.dataService.getConfigurationData().keepConstant;
  }

  public setVariableClicked(variable: VariableClicked | undefined): void {
    this.variableClicked = variable;
  }

  public dataTableType = (): DataTableType => this.variableClicked.dataTableType;

  public variable = (): Variable => this.variableClicked.variable;

  private seriesIndex = () => this.variableClicked.seriesIndex;

  private newValue = () => this.variableClicked.newValue;

  // UTILITIES

  public get variableClickedIsDependent(): boolean {
    return this.utils.isDependent(this.variable());
  }

  public get variableClickedIsIndependent(): boolean {
    return this.utils.isIndependent(this.variable());
  }

  private isEqualsToVariableClicked(variable: Variable): boolean {
    return this.utils.variableEquals(variable, this.variable());
  }

  public chartDataConfigurations(variables: Variable[], futureDataIndex: number, availableFutureData = this.availableFutureData) {
    return variables
    .map((e: Variable, index: number) => this.chartDataConfiguration(e, availableFutureData, index, futureDataIndex));
  }

  private chartDataConfiguration(
    variable: Variable,
    availableFutureData: boolean,
    index: number,
    futureDataIndex: number
  ) {
    const data = variable.values
    .map((value, i) => this.filterFutureValue(availableFutureData, value, i, futureDataIndex));
    const color = this.getColor(index);
    return ChartDataConfiguration({ data, variable, color});
  }

  private filterFutureValue(availableFutureData: boolean, value: VariableValue, variableIndex: number, futureDataIndex: number) {
    return !availableFutureData && futureDataIndex >= 0 && variableIndex > futureDataIndex ? null : value.value;
  }

  public getChartOptions(data: ChartData, maxYAxes: number, context: any, draggable: boolean) {
    return ChartConfig({
      title: data.title,
      maxYAxes,
      draggable,
      context,
      annotationValue: this.getAnnotations(data)
    });
  }

  public getAnnotations(data: ChartData): string {
    try {
      const annotationValue = data.series[data.futureDataIndex - 1].key;
      return annotationValue ? annotationValue : '';
    } catch (error) {
      return '';
    }
  }

  public getMaxYAxes(variables: Variable[]): number {
    const maxValue: number = [...variables]
    .map((variable) => this.getMaxValue(variable))
    .reduce((previous, current) => Math.max(previous, current));
    return Math.round(maxValue) + 10;
  }

  private getMaxValue(variable: Variable): number {
    const result = variable.values
    .map((value: VariableValue) => value.value)
    .reduce((previous, current) => Math.max(previous, current));

    return isFinite(result) ? result : 0;
  }

  public getColor(index: number): string {
    return Colors[index];
  }

  public getChartLabels = (chartData: ChartData): string[] => {
    return chartData.series.map(e => e.key);
  }

  public getChartData(data: Relationship, dataTable: DataTable): ChartData {
    const variables = dataTable.variables
    .filter(variable => this.findSelectedVariables(data, variable));

    const chartType = data.chartTypeSelectedItems[0];
    const dataTableType = data.dataTableType;
    const series = dataTable.series;
    const title = data.title;
    const id = data.id;
    const futureDataIndex = dataTable.futureDataIndex;
    const draggable = data.draggable;
    const comparison = data.comparison;

    return new ChartData(
      id,
      title,
      dataTableType,
      series,
      chartType,
      variables,
      futureDataIndex,
      draggable,
      comparison
    );
  }

  private processNewIndependentVariables(info: NewIndependentValue): void {
    const variables = this.getIndependentVariables()
    .map(e => this.updateVariable(info, e));

    this.updateVariables(variables);
    this.calculateDependentVariables();
  }

  private updateVariable(newVariable: NewIndependentValue, variable: Variable): Variable {
    if (variable.key === newVariable.variableKey) {
      variable.values[this.seriesIndex()].value = newVariable.value;
    }
    return variable;
  }

  private updateVariables(newVariables: Variable[]): void {
    const variables = this.variables.map(e => this.replaceVariable(newVariables, e));
    this.saveVariables(variables);
  }

  private replaceVariable(newVariables: Variable[], variable: Variable): Variable {
    let found: Variable;
    newVariables.forEach(v => {
      if (variable.md5 === v.md5) {
        found = v;
      }
    });
    return found ? found : variable;
  }

  private findSelectedVariables(data: Relationship, variable: Variable): SelectOption {
    return data.variablesSelectedItems.find(e => e.itemId === variable.md5);
  }

  public processDependentVariable(): void {
    const variable = this.calculateNewIndependentValue();
    this.processNewIndependentVariables(variable);
    this.chartCommunicationService.loadCharts();
  }

  private calculateNewIndependentValue(): NewIndependentValue {
    const keepVariableKey =  this.getKeepVariableKey();
    const keepVariableValue = this.variables
    .filter(variable => variable.key === keepVariableKey)
    .map(variable => variable.values[this.seriesIndex()].value)
    .reduce(value => value);

    const formula = this.variable().formula;
    const expression = this.mathService.replaceVariableValueInFormula(formula, keepVariableKey, keepVariableValue);
    const equation = this.mathService.addResultToFormula(expression, this.newValue());
    const solution = this.mathService.solveEquation(equation, this.changeVariableKey);

    return {
      variableKey: this.changeVariableKey,
      value: solution
    };
  }

  private calculateNewIndependentVariableValue(
    draggedIndependentVariableKey?: string,
    unselectedDependentVariable?: any,
    independentVariables?: Array<any>,
    seriesIndex?: number
  ): NewIndependentValue {
    let variableToChange = String('');
    const draggedIndependentVariableValue = this.variables
    .filter(variable => variable.key === draggedIndependentVariableKey)
    .map(variable => variable.values[this.seriesIndex()].value)
    .reduce(value => value);
    const formula = unselectedDependentVariable.itemId.dependentVariable.formula;
    const expression = this.mathService.replaceVariableValueInFormula(formula, draggedIndependentVariableKey, draggedIndependentVariableValue);
    const dependentValue = unselectedDependentVariable.itemId.dependentVariable.values[seriesIndex].value;
    const equation = this.mathService.addResultToFormula(expression, dependentValue);
    independentVariables.forEach(variable => {
      equation.includes(variable) ? variableToChange = variable : null
    });
    const solution = this.mathService.solveEquation(equation, variableToChange);
    
    return {
      variableKey: variableToChange,
      value: solution
    }
  }

  public calculateDependentVariables(unselectedDependentVariables?: any): void {
    this.conversionMetricsService.calculateAndSaveDependentVariables(this.dataTableType(), unselectedDependentVariables, this.variableClickedIsIndependent);
    this.chartCommunicationService.loadCharts();
  }
  
  public calculateIndependentVariables(unselectedDependentVariables?: any, independentVariables?: Array<any>, seriesIndex?: number) : void {
    const draggedIndependentVariableKey =  this.getKeepVariableKey();
    let recalculatedVariable = Object();
    
    unselectedDependentVariables.forEach(unselectedDependentVariable => {
      const formula = unselectedDependentVariable.itemId.dependentVariable.formula;      
      if (formula.includes(draggedIndependentVariableKey)) {
        recalculatedVariable = this.calculateNewIndependentVariableValue(draggedIndependentVariableKey, unselectedDependentVariable, independentVariables, seriesIndex);
        this.processNewIndependentVariables(recalculatedVariable);
        this.chartCommunicationService.loadCharts();
      } else if (formula.includes(recalculatedVariable.variableKey)) {
        recalculatedVariable = this.calculateNewIndependentVariableValue(recalculatedVariable.variableKey, unselectedDependentVariable, independentVariables, seriesIndex);
        this.processNewIndependentVariables(recalculatedVariable);
        this.chartCommunicationService.loadCharts();
      }
    });
  }

  public processIndependentVariable(): void {
    if (this.variableClickedIsIndependent && !this.keepMetric) {
      this.replaceVariableValue();
      this.updateVariables([this.variableClicked.variable]);

      this.calculateDependentVariables();
    }
  }

  private replaceVariableValue(): void {
    const newValue = +this.newValue();
    this.variableClicked.variable.values[this.seriesIndex()].value = newValue.toFixed(2);
  }

  public getSelectedDependentVariables(): Variable[] {
    const selected = this.getMD5OfSelectedVariables();
    const variables = this.getDependentVariables()
    .filter(e => selected.includes(e.md5));
    return variables;
  }

  private getMD5OfSelectedVariables(): string[] {
    const selectedVariables = this.dataService.getRelationships()
    .filter(e => e.dataTableType === this.dataTableType())
    .map(e => e.variablesSelectedItems)
    .reduce((previous, current) => previous.concat(current))
    .map(e => e.itemId);

    return Array.from(new Set(selectedVariables));
  }

  // TODO: set type
  private getXFactor(changedVariableKey: string, dependentVariableFormula: string) {
    return this.variables
    .map((e, index) => this.createVariableIndex(e, index))
    .filter(e => e.variable.key !== changedVariableKey && dependentVariableFormula.includes(e.variable.key))
    .reduce(e => e);
  }

  // TODO: set type
  private createVariableIndex(variable: Variable, variableIndex: number) {
    return { variableIndex, variable };
  }

  public findSolutions(keepVariables: any[], results: any[], iteration: number): void {
    if (iteration > 5) {
      return;
    }
    keepVariables.forEach(item => this.findSolution(item, results));
    this.findSolutions(keepVariables, results, iteration + 1);
  }

  // TODO: set parameters types
  private findSolution(item, results): void {
    const variable: Variable = item.dependentVariable;
    const dependentKeys = variable.formula.match(this.textMatch);
    dependentKeys.forEach(e => this.solveEquation(e, results, variable, item));
  }

  // TODO: set parameters types
  private solveEquation(dependentKey, results, variable, item): void {
    const key = results.find(e => e.key === dependentKey);
    if (key) {
      const substitution = variable.formula.replace(key.key, key.value);
      const dependentVariableValue = variable.values[item.serieIndex].value;
      const equation = `${substitution}=${dependentVariableValue}`;
      const xVariable = this.getXFactor(key.key, variable.formula);
      const x = xVariable.variable.key;
      const solution = this.mathService.solveEquation(equation, x);
      const exists = results.find(e => e.key === x);
      this.pushIfNotExist(exists, x, solution, results);
    }
  }

  // TODO: set parameters types
  private pushIfNotExist(exist, x, solution, results): void {
    if (!exist) {
      results.push({ key: x , value: solution });
    }
  }

  public updateIndependentVariables(results: any[], selectedVariables: ItemId [] | any[]): void {
    this.findSolutions(selectedVariables, results, 1);

    const variables = this.getIndependentVariables()
    .map(e => this.findResultAndSetNewValue(e, results));
    this.updateVariables(variables);
  }

  private findResultAndSetNewValue(variable: Variable, results: any[]): Variable {
    const result = results.find(e => e.key === variable.key);
    if (result) {
      variable.values[this.seriesIndex()].value = result.value;
    }
    return variable;
  }

  public filterByChartVariables(variables: Variable[]): Variable[] {
    return this.variables.filter(v => variables.find(e => this.utils.variableEquals(e, v)));
  }

  private assignNewValue(variable: Variable): Variable {
    if (this.isEqualsToVariableClicked(variable)) {
      variable.values[this.seriesIndex()].value = this.newValue();
    }
    return variable;
  }

  // TODO: set type and parameter type
  private createKeepVariablesSelectedItems(keepVariablesSelectedItems) {
    return keepVariablesSelectedItems
    .map(e => e.itemId.dependentVariable)
    .map(dependent => this.assignNewValue(dependent))
    .map(dependent => this.createItem(dependent));
  }

  public updateAndEmitChanges(
    keepVariablesSelectedItems: VariableItem[],
    optionSelectedItems: VariableItem[] | any[],
    allOptions: VariableItem[] | any[],
  ): void {
    //const results = [];
    const results: KeyValue[] = [];
    const newValue = this.getNewIndependentValue();
    results.push(newValue);

    const thirdGradeRelations: PairIndependetDependetVariables[] = this.getThridGradeRelationIndependentVaribales(
      keepVariablesSelectedItems,
      optionSelectedItems,
      allOptions,
    );
    if (thirdGradeRelations.length) {
      thirdGradeRelations.forEach(thirdGradeRelation => {
        const thirdGradeIndValue =  this.calculateThirdGradeIndependentValue(
          results,
          thirdGradeRelation
        );
        if (thirdGradeIndValue) {
          results.push({
            key: thirdGradeIndValue.variableKey,
            value: thirdGradeIndValue.value
          });
        }
      });
    }

    const dependentVariables = thirdGradeRelations.map(
      thirdGradeRelation => thirdGradeRelation.dependent.itemId
    );
    this.updateIndependentVariables(
      results,
      dependentVariables,
    );
    this.calculateDependentVariables();

    this.variables.forEach(e => {
      this.processNewIndependentVariables({
        variableKey: e.key,
        value: e.values[this.seriesIndex()].value
      });
    });

    this.chartCommunicationService.loadCharts();
  }

  private getThridGradeRelationIndependentVaribales(
    keepVariablesSelectedItems: VariableItem[],
    impactedIndependentVariables: VariableItem[],
    allOptions: VariableItem[],
  ): PairIndependetDependetVariables[] {
    const pairDependentIndependenVariables: PairIndependetDependetVariables[] = [];
    const dependetVariablesShouldNotVary = allOptions.filter(
      option => {
        const selected = keepVariablesSelectedItems.find(keepVariable => keepVariable.name === option.name);
        return !selected;
      }
    );

    if (!dependetVariablesShouldNotVary.length) {
      return pairDependentIndependenVariables;
    }

    this.getIndependentVariables().forEach((variable) => {
      dependetVariablesShouldNotVary.filter((keepVariable) => {
        if (keepVariable.itemId.dependentVariable.formula.includes(variable.key)) {
          if (impactedIndependentVariables.find(indVariable => indVariable.name !== variable.key)) {
            pairDependentIndependenVariables.push({
              independent: variable,
              dependent: keepVariable
            });
          }
        }
      });
    });

    return pairDependentIndependenVariables;
  }

  private calculateThirdGradeIndependentValue(
    results: KeyValue[],
    pairIndependentDependentVariable: PairIndependetDependetVariables
  ): NewIndependentValue {
    const { independent, dependent } = pairIndependentDependentVariable;
    let variableToReplace: KeyValue;
    results.forEach(result => {
      if (dependent.itemId.dependentVariable.formula.includes(result.key)) {
        variableToReplace = result;
      }
    });

    if (!variableToReplace) {
      return null;
    }

    const formula = dependent.itemId.dependentVariable.formula;
    const expression = this.mathService.replaceVariableValueInFormula(
      formula,
      variableToReplace.key,
      variableToReplace.value,
    );
    const value = dependent.itemId.dependentVariable.values[this.seriesIndex()].value;
    const equation = this.mathService.addResultToFormula(expression, value);
    const solution = this.mathService.solveEquation(equation, independent.key);

    return {
      variableKey: independent.key,
      value: solution
    };
  }

  private getNewIndependentValue(): KeyValue {
    const value = this.calculateNewIndependentValue();
    return { key: value.variableKey , value: value.value };
  }

  public getKeepVariablesSelectedItems(): VariableItem[] {
    const selectedItems: string[] = this.dataService.getSelectedItems(this.variable().md5).selectedItems;

    return this.getSelectedDependentVariables()
    .filter(variable => selectedItems.find(item => item === variable.md5))
    .map(variable => this.getDependentVariableItem(variable));
  }

  public getDependentsVariablesExcludeVariableClicked(): Variable[] {
    return this.getSelectedDependentVariables()
    .filter(e => !this.isEqualsToVariableClicked(e));
  }

  public getKeepVariablesDropdownList(variables: Variable[]): VariableItem[] {
    return variables.map(e => this.getDependentVariableItem(e));
  }

  // TODO: set type
  public getDropdownOptions() {
    // MEJORAR ESTA FUNCIONALIDAD
    return this.getIndependentVariables()
    .filter(e => this.variable().formula.includes(e.key))
    .map(e => this.createDropdownOption(e));
  }

  private getKeepVariableKey(): string {
    const dropdownOptions = this.getDropdownOptions();
    return dropdownOptions
      .map(option => option.itemId.key)
      .filter(key => key !== this.changeVariableKey)
      .reduce(key => key);
  }

  // TODO: set type
  private createDropdownOption(variable: Variable) {
    return {
      itemId: {
        key: variable.key
      },
      name: variable.key
    };
  }

  private getDependentVariableItem(variable: Variable): VariableItem {
    return {
      itemId: {
        serieIndex: this.seriesIndex(),
        dependentVariable: variable
      },
      name: variable.key
    };
  }

  private createItem(variable: Variable): ItemId {
    return {
      serieIndex: this.seriesIndex(),
      dependentVariable: variable
    };
  }

  public get hasSelectedData(): boolean {
    const data = this.dataService.getSelectedItems(this.variable().md5);
    return data && data.variable === this.variable().md5 && data.dataTableType === this.dataTableType();
  }

  public saveSelectedData(items: VariableItem[] = [], allItems?: VariableItem[]): void {
    const unselectedItems = [];
    allItems.forEach(item => {
      const selected = items.find(i => i.itemId === item.itemId);
      if (!selected) {
        unselectedItems.push(item);
      }
    });
    const data: SelectedItems = {
      variable: this.variable().md5,
      dataTableType: this.dataTableType(),
      changeVariableKey: this.changeVariableKey,
      keepVariableKey: this.keepMetric ? null : this.getKeepVariableKey(),
      selectedItems: this.keepMetric ? unselectedItems.map(e => e.itemId.dependentVariable.md5) : null
    };
    this.dataService.saveSelectedItems(data);
  }

  public get variables(): Variable[] {
    return this.dataService.getVariables(this.dataTableType());
  }

  private saveVariables = (variables: Variable[]): void => this.dataService.saveVariables(this.dataTableType(), variables);

  private getDependentVariables = (): Variable[] => this.variables.filter(e => this.utils.isDependent(e));

  private getIndependentVariables = (): Variable[] => this.variables.filter(e => this.utils.isIndependent(e));

}
